define('common/components/learn-router/views/search-opt-in-out-banner',[
    "app",
    "@discoveryedu/nebula-components",
    "vue",
    "common/components/vue-wrapper/helpers/mount-vue-component",
    "i18next",
    "njs!common/components/edde/templates/nav/includes/product_bar/_search_opt_in_out_banner(params:tmpl=edde/nav/includes/product_bar/_search_opt_in_out_banner.html)"
], function (
    app,
    NebulaComponents,
    Vue,
    mountVueComponent,
    i18next,
    template
) {
    "use strict";

    return Mn.LayoutView.extend({

        "template": template,

        "el": "#search-opt-in-out-banner-container",

        "vueInstance": null,

        "events": {
            "click #searchOptInOut": "toggleNewSearch",
        },

        "newSearchOn": false,

        "optInText": i18next.t("Try New Search (Beta)"),

        "optOutText": i18next.t("Turn Off New Search"),

        "initialize": function(opts) {
            // if this is a learn router page, attach this listener
            // so this view is redrawn when the current page changes
            if (opts && opts.navigation) {
                this.listenTo(opts.navigation, {
                    "change": this.render
                });
            }

            this.checkIfNewSearchIsOnPageLoad();
        },

        "checkIfNewSearchIsOnPageLoad": function() {
            var url = '/apiproxy/v1/search/user/search_browse_setting';
            $.ajax({
                "method": "GET",
                "url": url,
                "success": _.bind(function(res) {
                    this.newSearchOn = res.user_can_see_browse_search;
                    this.setInitialSwitchState();
                }, this),
            });
        },

        "onRender": function() {
            this.vueInstance = mountVueComponent(
                NebulaComponents.NebulaSwitch,
                '#search-opt-in-out-switch-container',
                {
                    "isCompressed": true,
                    "labelText": this.optInText,
                }, {
                "checkbox": _.bind(function() {
                    return Vue.h(NebulaComponents.NebulaInput, {
                    // Attributes
                    "checked": this.newSearchOn,
                    "class": "nebula-switch__input",
                    "id": "searchOptInOut",
                    "name": "search-opt-in-out",
                    "role": "switch",
                    "type": "checkbox",

                    // Listeners
                    "onChange": _.bind(function(e) {
                        var baseUrl = "/apiproxy/v1/search/user/search_browse_setting";
                        var fullUrl = "";

                        if (e.target.checked === true) {
                            fullUrl = baseUrl + "?value=True";
                        } else {
                            fullUrl = baseUrl + "?value=False";
                        }

                        $.ajax({
                            "method": "POST",
                            "url": fullUrl,
                            "success": _.bind(function(res) {
                                var switchTextEl = document.getElementById('search-opt-in-out-switch-container').getElementsByClassName('nebula-switch__text')[0],
                                    redirectUrl = null;

                                this.newSearchOn = res.user_can_see_browse_search;

                                if (!res.user_can_see_browse_search) {
                                    document.getElementById('search-opt-in-out-banner__sr-alert--switch-off').classList.remove('search-opt-in-out-banner__sr-alert--inactive');
                                    switchTextEl.innerText = this.optInText;
                                    redirectUrl = "/learn/search";
                                    window.DEAnalytics.linkClicked({
                                        'name': 'Try New Search - Out',
                                        'type': 'New Search Experience',
                                    });
                                } else if (res.user_can_see_browse_search) {
                                    document.getElementById('search-opt-in-out-banner__sr-alert--switch-on').classList.remove('search-opt-in-out-banner__sr-alert--inactive');
                                    switchTextEl.innerText = this.optOutText;
                                    redirectUrl = "/learn/search-browse";
                                    window.DEAnalytics.linkClicked({
                                        'name': 'Try New Search - In',
                                        'type': 'New Search Experience',
                                    });
                                }
                                if (this.model.get('init_data').search_term) {
                                    redirectUrl += '?q=' + this.model.get('init_data').search_term;
                                }
                                setTimeout(function() {
                                    var $alerts = document.getElementsByClassName('search-opt-in-out-banner__sr-alert');
                                    $alerts.forEach(function(alert) {
                                        alert.classList.add('search-opt-in-out-banner__sr-alert--inactive');
                                    });
                                }, 3000);
                                Backbone.Radio.channel('learn-router').trigger('router:navigate', redirectUrl);
                            }, this),
                        });
                    }, this),
                });
                }, this),
            });
        },

        "setInitialSwitchState": function() {
            var switchInputEl = document.getElementById('search-opt-in-out-switch-container').getElementsByClassName('nebula-switch__input')[0];
            if (this.newSearchOn) {
                switchInputEl.checked = true;
            } else {
                switchInputEl.checked = false;
            }
            this.setSwitchText();
        },

        "setSwitchText": function() {
            var switchTextEl = document.getElementById('search-opt-in-out-switch-container').getElementsByClassName('nebula-switch__text')[0];
            if (this.newSearchOn) {
                switchTextEl.innerText = this.optOutText;
            } else {
                switchTextEl.innerText = this.optInText;
            }
        },

        "onBeforeDestroy": function() {
            if (this.vueInstance) {
                this.vueInstance.unmount();
            }
        }
    });
});

