(function() {(window.nunjucksPrecompiled = window.nunjucksPrecompiled || {})["edde/nav/includes/product_bar/_breadcrumbs.html"] = (function() {
function root(env, context, frame, runtime, cb) {
var lineno = 0;
var colno = 0;
var output = "";
try {
var parentTemplate = null;
if(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "page_data")),"navigation")),"breadcrumbs")) {
output += "\n    <nav class=\"comet-breadcrumb--2018\">\n        <ul class=\"comet-product-bar__breadcrumbs\">\n            ";
frame = frame.push();
var t_3 = runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "page_data")),"navigation")),"breadcrumbs");
if(t_3) {t_3 = runtime.fromIterator(t_3);
var t_2 = t_3.length;
for(var t_1=0; t_1 < t_3.length; t_1++) {
var t_4 = t_3[t_1];
frame.set("breadcrumb", t_4);
frame.set("loop.index", t_1 + 1);
frame.set("loop.index0", t_1);
frame.set("loop.revindex", t_2 - t_1);
frame.set("loop.revindex0", t_2 - t_1 - 1);
frame.set("loop.first", t_1 === 0);
frame.set("loop.last", t_1 === t_2 - 1);
frame.set("loop.length", t_2);
output += "\n                ";
if(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "loop")),"index0") != 0) {
output += "\n                    <li class=\"comet-product-bar__breadcrumb-separator\" aria-hidden=\"true\">\n                        <svg class=\"comet-product-bar__breadcrumb-separator-icon\">\n                            <use xlink:href=\"";
output += runtime.suppressValue((lineno = 7, colno = 59, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "edde_static"), "edde_static", context, ["comet/2.x/comet.svg#caret-right"])), env.opts.autoescape);
output += "\"></use>\n                        </svg>\n                    </li>\n                ";
;
}
output += "\n                ";
if(env.getFilter("length").call(context, runtime.memberLookup((t_4),"dropdown")) > 1) {
output += "\n                    <li class=\"comet-product-bar__breadcrumb navigation-flyout__trigger-container\">\n                        ";
var tasks = [];
tasks.push(
function(callback) {
env.getTemplate("edde/nav/includes/product_bar/_flyout_trigger.html", false, "edde/nav/includes/product_bar/_breadcrumbs.html", false, function(t_6,t_5) {
if(t_6) { cb(t_6); return; }
callback(null,t_5);});
});
tasks.push(
function(template, callback){
template.render(context.getVariables(), frame, function(t_8,t_7) {
if(t_8) { cb(t_8); return; }
callback(null,t_7);});
});
tasks.push(
function(result, callback){
output += result;
callback(null);
});
env.waterfall(tasks, function(){
output += "\n                    </li>\n                ";
});
}
else {
output += "\n                    <li class=\"comet-product-bar__breadcrumb\">\n                        <a class=\"comet-product-bar__breadcrumb-link comet-button global-nav-ga-event comet-button--flat";
output += runtime.suppressValue((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "loop")),"last")?" comet-product-bar__breadcrumb-link--static":""), env.opts.autoescape);
output += "\" href=\"";
output += runtime.suppressValue(runtime.memberLookup((t_4),"link"), env.opts.autoescape);
output += "\" data-gac=\"Link\" data-gaa=\"Click\" data-gal=\"Breadcrumb|";
output += runtime.suppressValue(runtime.memberLookup((t_4),"text"), env.opts.autoescape);
output += "\">\n                            ";
if(runtime.memberLookup((t_4),"truncated_text")) {
output += "\n                                <span class=\"comet-product-bar__breadcrumb-truncated-text\" id=\"tm__bctrunctext_";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "loop")),"index"), env.opts.autoescape);
output += "\">\n                                    ";
output += runtime.suppressValue(runtime.memberLookup((t_4),"truncated_text"), env.opts.autoescape);
output += "\n                                </span>\n                            ";
;
}
output += "\n                            ";
if(runtime.memberLookup((t_4),"dim_text")) {
output += "\n                                <span class=\"comet-product-bar__breadcrumb-dim-text\" id=\"tm__bcdimtext_";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "loop")),"index"), env.opts.autoescape);
output += "\">\n                                    ";
output += runtime.suppressValue(runtime.memberLookup((t_4),"dim_text"), env.opts.autoescape);
output += "\n                                </span>\n                                <br>\n                            ";
;
}
output += "\n                            <span class=\"comet-product-bar__breadcrumb-text\" id=\"tm__bctext_";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "loop")),"index"), env.opts.autoescape);
output += "\">\n                                ";
output += runtime.suppressValue(runtime.memberLookup((t_4),"text"), env.opts.autoescape);
output += "\n                            </span>\n                        </a>\n                    </li>\n                ";
;
}
output += "\n            ";
;
}
}
frame = frame.pop();
output += "\n        </ul>\n    </nav>\n    <div class=\"comet-modal-overlay comet-flyout-overlay navigation-flyout-overlay comet-modal-overlay--backdrop\" role=\"dialog\"></div>\n";
;
}
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};

})();
})();

define("common/components/edde/templates/nav/includes/product_bar/_breadcrumbs", function(){});

