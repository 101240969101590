define('js/de/helper/storage',[], function() {
    /*global Modernizr:false */
    "use strict";

    var modernizrStorage = null;

    if (window.Modernizr) {
        modernizrStorage = window.Modernizr.localstorage
                            && window.Modernizr.sessionstorage;
    }

    var Storage = function(storage) {
        this.queue = storage;
        return this;
    };

    Storage.prototype.get = function(name, json) {
        if (!modernizrStorage) { return false; }
        if (typeof name === 'undefined') { return this.queue; }
        var instance = this.queue.getItem(name);
        if (!instance) { return false; }
        return json ? instance : JSON.parse(instance);
    };

    Storage.prototype.set = function(name, content) {
        if (arguments.length <= 1 || !modernizrStorage) { return false; }
        content = JSON.stringify(content);
        this.queue.setItem(name, content);
        return this.get(name);
    };

    Storage.prototype.clear = function(name) {
        if (!modernizrStorage) { return this; }
        if (typeof name === 'undefined') { return this.queue.clear(); }
        delete this.queue[name];
        return this;
    };

    return {
        local   : new Storage(window.localStorage || {}),
        session : new Storage(window.sessionStorage || {})
    };

});
