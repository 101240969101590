(function() {(window.nunjucksPrecompiled = window.nunjucksPrecompiled || {})["common/share/includes/_share.html"] = (function() {
function root(env, context, frame, runtime, cb) {
var lineno = 0;
var colno = 0;
var output = "";
try {
var parentTemplate = null;
var t_1;
t_1 = (runtime.contextOrFrameLookup(context, frame, "headline")?(lineno = 0, colno = 37, runtime.callWrap(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "headline")),"replace"), "headline[\"replace\"]", context, ["'","’"])):"");
frame.set("ms_headline", t_1, true);
if(frame.topLevel) {
context.setVariable("ms_headline", t_1);
}
if(frame.topLevel) {
context.addExport("ms_headline", t_1);
}
output += "\n";
var t_2;
t_2 = (runtime.contextOrFrameLookup(context, frame, "description")?(lineno = 1, colno = 43, runtime.callWrap(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "description")),"replace"), "description[\"replace\"]", context, ["'","’"])):"");
frame.set("ms_description", t_2, true);
if(frame.topLevel) {
context.setVariable("ms_description", t_2);
}
if(frame.topLevel) {
context.addExport("ms_description", t_2);
}
output += "\n\n";
if(runtime.contextOrFrameLookup(context, frame, "ms_headline") && runtime.contextOrFrameLookup(context, frame, "ms_description")) {
output += "\n    ";
var t_3;
t_3 = runtime.contextOrFrameLookup(context, frame, "ms_headline") + " - " + runtime.contextOrFrameLookup(context, frame, "ms_description");
frame.set("description_msg", t_3, true);
if(frame.topLevel) {
context.setVariable("description_msg", t_3);
}
if(frame.topLevel) {
context.addExport("description_msg", t_3);
}
output += "\n";
;
}
else {
output += "\n    ";
if(runtime.contextOrFrameLookup(context, frame, "ms_description")) {
output += "\n        ";
var t_4;
t_4 = runtime.contextOrFrameLookup(context, frame, "ms_description");
frame.set("description_msg", t_4, true);
if(frame.topLevel) {
context.setVariable("description_msg", t_4);
}
if(frame.topLevel) {
context.addExport("description_msg", t_4);
}
output += "\n    ";
;
}
else {
output += "\n        ";
var t_5;
t_5 = runtime.contextOrFrameLookup(context, frame, "ms_headline");
frame.set("description_msg", t_5, true);
if(frame.topLevel) {
context.setVariable("description_msg", t_5);
}
if(frame.topLevel) {
context.addExport("description_msg", t_5);
}
output += "\n    ";
;
}
output += "\n";
;
}
output += "\n\n<div class=\"comet-dialog__inner comet-long-form-text social-sharing\">\n\n    <div class=\"social-buttons\">\n\n        <a class=\"teams-share-button\" data-icon-px-size=\"29\" data-href=\"";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "link"), env.opts.autoescape);
output += "\" data-msg-text=\"";
output += runtime.suppressValue(env.getFilter("truncate").call(context, runtime.contextOrFrameLookup(context, frame, "description_msg"),175,true,"..."), env.opts.autoescape);
output += "\" data-assign-title=\"";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "ms_headline"), env.opts.autoescape);
output += "\">\n            Microsoft Share to Team\n            ";
output += runtime.suppressValue((lineno = 19, colno = 16, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "_"), "_", context, ["opens in a new window"])), env.opts.autoescape);
output += "\n        </a>\n\n        <div class=\"share-g-classroom-container\">\n            <div id=\"gs-share-classroom\" class=\"g-sharetoclassroom\" data-size=\"32\" data-url=\"";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "link"), env.opts.autoescape);
output += "\" data-title=\"Discovery Education - ";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "headline"), env.opts.autoescape);
output += "\" data-onsharestart=\"googleCRShareCB\"></div>\n        </div>\n\n        <a class=\"share-button fb-share-button\" href=\"//www.facebook.com/sharer/sharer.php?u=";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "link"), env.opts.autoescape);
output += "&title=";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "headline"), env.opts.autoescape);
output += " via Discovery Education\" data-window-features=\"menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=485,width=640\">\n            Facebook\n            ";
output += runtime.suppressValue((lineno = 28, colno = 16, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "_"), "_", context, ["opens in a new window"])), env.opts.autoescape);
output += "\n        </a>\n\n        <a class=\"share-button twitter-share-button\" href=\"//twitter.com/intent/tweet?url=";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "link"), env.opts.autoescape);
output += "&text=";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "headline"), env.opts.autoescape);
output += " via ";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "shareInfo")),"twitterHandle"), env.opts.autoescape);
output += "\" data-window-features=\"menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=485,width=640\">\n            Tweet\n            ";
output += runtime.suppressValue((lineno = 33, colno = 16, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "_"), "_", context, ["opens in a new window"])), env.opts.autoescape);
output += "\n        </a>\n\n        <a class=\"share-button email-share-button\" href=\"mailto:?to=&body=";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "link"), env.opts.autoescape);
output += "&subject=";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "headline"), env.opts.autoescape);
output += " via Discovery Education\">";
output += runtime.suppressValue((lineno = 36, colno = 133, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "_"), "_", context, ["EMAIL"])), env.opts.autoescape);
output += "</a>\n\n        <a class=\"share-button edmodo-share-button\" href=\"https://www.edmodo.com/home?share=1&source=1981166&url=";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "link"), env.opts.autoescape);
output += "&desc=";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "headline"), env.opts.autoescape);
output += " via Discovery Education\" data-window-features=\"menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=485,width=640\">\n            Edmodo\n            ";
output += runtime.suppressValue((lineno = 40, colno = 16, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "_"), "_", context, ["opens in a new window"])), env.opts.autoescape);
output += "\n        </a>\n\n        <a class=\"share-button remind-share-button\" href=\"https://www.remind.com/v1/share?url=";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "link"), env.opts.autoescape);
output += "&referrer=";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "referrer"), env.opts.autoescape);
output += "&text=";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "headline"), env.opts.autoescape);
output += " via Discovery Education\" data-window-features=\"menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=600,width=1024\">\n            Remind\n            ";
output += runtime.suppressValue((lineno = 45, colno = 16, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "_"), "_", context, ["opens in a new window"])), env.opts.autoescape);
output += "\n        </a>\n\n        <a class=\"share-button pinterest-share-button\" href=\"javascript:void(0)\">Pinterest</a>\n\n    </div>\n</div>\n\n<div class=\"comet-form__field-group\">\n    <label class=\"comet-form__label\" for=\"copy-link\">\n        ";
output += runtime.suppressValue((lineno = 55, colno = 12, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "_"), "_", context, ["Copy Link"])), env.opts.autoescape);
output += "\n    </label>\n    <input id=\"copy-link\" class=\"comet-form__input\" value=\"";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "link"), env.opts.autoescape);
output += "\" type=\"text\" readonly>\n    <div class=\"copy-link-swf\">\n        <div id=\"copy-to-clipboard-btn\"></div>\n    </div>\n</div>\n\n<div class=\"comet-dialog__button-row\">\n    <button class=\"dismiss-modal comet-button comet-button--ghost comet-dialog__button comet-dialog__button--cancel\" data-trigger=\"dismiss\">\n        ";
output += runtime.suppressValue((lineno = 65, colno = 12, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "_"), "_", context, ["Close"])), env.opts.autoescape);
output += "\n    </button>\n\n    <!-- default to not show this, display if the browser is able to run execCommand('copy') -->\n    <button class=\"comet-button comet-button--primary comet-dialog__button\" style=\"display:none;\">\n        ";
output += runtime.suppressValue((lineno = 70, colno = 12, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "_"), "_", context, ["Copy"])), env.opts.autoescape);
output += "\n    </button>\n\n</div>\n\n";
(parentTemplate ? function(e, c, f, r, cb) { cb(""); } : context.getBlock("scripts"))(env, context, frame, runtime, function(t_7,t_6) {
if(t_7) { cb(t_7); return; }
output += t_6;
output += "\n\n<!--\n    # Place here for translating into .po file\n    ";
output += runtime.suppressValue((lineno = 81, colno = 8, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "_"), "_", context, ["The link has been copied to your clipboard"])), env.opts.autoescape);
output += "\n -->\n\n";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
});
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
function b_scripts(env, context, frame, runtime, cb) {
var lineno = 75;
var colno = 3;
var output = "";
try {
var frame = frame.push(true);
output += "\n<script async defer src=\"https://teams.microsoft.com/share/launcher.js\"></script>\n";
cb(null, output);
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
b_scripts: b_scripts,
root: root
};

})();
})();

define("common/templates/share/includes/_share", function(){});

