(function() {(window.nunjucksPrecompiled = window.nunjucksPrecompiled || {})["edde/nav/includes/product_bar/_multisite.html"] = (function() {
function root(env, context, frame, runtime, cb) {
var lineno = 0;
var colno = 0;
var output = "";
try {
var parentTemplate = null;
output += "<li class=\"comet-list-group__row comet-list-group__row-multisite\">\n    <div class=\"comet-list-group__multisite-inner\">\n        <div class=\"comet-list-group__multisite-item\">\n            ";
output += runtime.suppressValue((lineno = 3, colno = 16, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "_"), "_", context, ["Current School"])), env.opts.autoescape);
output += "\n        </div>\n        <div class=\"comet-list-group__multisite-item comet-list-group__multisite-item--site\">\n            ";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "page_data")),"navigation")),"user")),"current_site")),"name"), env.opts.autoescape);
output += "\n        </div>\n        <div class=\"comet-list-group__multisite-item\">\n            <a class=\"comet-button comet-button--primary comet-list-group__multisite-button\" href=\"";
output += runtime.suppressValue((lineno = 9, colno = 112, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "get_DE_url"), "get_DE_url", context, ["app"])), env.opts.autoescape);
output += "/learn/profile/switch_site?next=";
output += runtime.suppressValue(env.getFilter("encode_uri_component").call(context, runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "init_data")),"full_path")), env.opts.autoescape);
output += "\">";
output += runtime.suppressValue((lineno = 9, colno = 206, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "_"), "_", context, ["Switch School"])), env.opts.autoescape);
output += "</a>\n        </div>\n    </div>\n</li>\n";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};

})();
})();

define("common/components/edde/templates/nav/includes/product_bar/_multisite", function(){});

