(function() {(window.nunjucksPrecompiled = window.nunjucksPrecompiled || {})["edde/nav/includes/product_bar/_flyout_menu.html"] = (function() {
function root(env, context, frame, runtime, cb) {
var lineno = 0;
var colno = 0;
var output = "";
try {
var parentTemplate = null;
output += "<div class=\"comet-row comet-flyout navigation-flyout\" aria-labelledby=\"breadcrumb-flyout-trigger\" role=\"region\">\n    <div class=\"comet-row__inner\">\n        <div class=\"navigation-flyout__back-links-mobile-container\">\n            <div class=\"comet-list-group__list\">\n            ";
frame = frame.push();
var t_3 = runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "page_data")),"navigation")),"breadcrumbs");
if(t_3) {t_3 = runtime.fromIterator(t_3);
var t_2 = t_3.length;
for(var t_1=0; t_1 < t_3.length; t_1++) {
var t_4 = t_3[t_1];
frame.set("breadcrumb", t_4);
frame.set("loop.index", t_1 + 1);
frame.set("loop.index0", t_1);
frame.set("loop.revindex", t_2 - t_1);
frame.set("loop.revindex0", t_2 - t_1 - 1);
frame.set("loop.first", t_1 === 0);
frame.set("loop.last", t_1 === t_2 - 1);
frame.set("loop.length", t_2);
output += "\n                <li class=\"comet-list-group__row\">\n                    <a class=\"comet-list-group__row-anchor global-nav-ga-event\" href=\"";
output += runtime.suppressValue(runtime.memberLookup((t_4),"link"), env.opts.autoescape);
output += "\" data-gac=\"Link\" data-gaa=\"Click\" data-gal=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "page_data")),"navigation")),"product_name"), env.opts.autoescape);
output += "|";
output += runtime.suppressValue(env.getFilter("capitalize").call(context, runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "page_data")),"google_analytics")),"page_type")), env.opts.autoescape);
output += "|";
output += runtime.suppressValue(runtime.memberLookup((t_4),"text"), env.opts.autoescape);
output += "\">\n                        <svg class=\"comet-list-group__row-type-icon\" aria-hidden=\"true\" focusable=\"false\">\n                            <use xlink:href=\"";
output += runtime.suppressValue((lineno = 8, colno = 59, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "edde_static"), "edde_static", context, ["comet/2.x/comet.svg#caret-left"])), env.opts.autoescape);
output += "\"></use>\n                        </svg>\n                        <span class=\"comet-list-group__row-title\">\n                            ";
if(runtime.memberLookup((t_4),"dim_text")) {
output += "\n                                <span class=\"comet-list-group__row-title-dim-text\">";
output += runtime.suppressValue(runtime.memberLookup((t_4),"dim_text"), env.opts.autoescape);
output += "</span>\n                            ";
;
}
output += "\n                            <span class=\"comet-list-group__row-title-text\">";
output += runtime.suppressValue(runtime.memberLookup((t_4),"text"), env.opts.autoescape);
output += "</span>\n                        </span>\n                    </a>\n                </li>\n            ";
;
}
}
frame = frame.pop();
output += "\n            </div>\n        </div>\n        <ul class=\"comet-list-group__list navigation-flyout__list\">\n            ";
frame = frame.push();
var t_7 = runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "dropdown_obj")),"dropdown");
if(t_7) {t_7 = runtime.fromIterator(t_7);
var t_6 = t_7.length;
for(var t_5=0; t_5 < t_7.length; t_5++) {
var t_8 = t_7[t_5];
frame.set("dropdown_item", t_8);
frame.set("loop.index", t_5 + 1);
frame.set("loop.index0", t_5);
frame.set("loop.revindex", t_6 - t_5);
frame.set("loop.revindex0", t_6 - t_5 - 1);
frame.set("loop.first", t_5 === 0);
frame.set("loop.last", t_5 === t_6 - 1);
frame.set("loop.length", t_6);
output += "\n                <li class=\"comet-list-group__row";
output += runtime.suppressValue((runtime.memberLookup((t_8),"selected")?" " + "comet-list-group__row--selected":""), env.opts.autoescape);
output += "\">\n                    <a class=\"comet-list-group__row-anchor global-nav-ga-event\" href=\"";
output += runtime.suppressValue(runtime.memberLookup((t_8),"link"), env.opts.autoescape);
output += "\" data-gac=\"Link\" data-gaa=\"Click\" data-gal=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "page_data")),"navigation")),"product_name"), env.opts.autoescape);
output += "|";
output += runtime.suppressValue(env.getFilter("capitalize").call(context, runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "page_data")),"google_analytics")),"page_type")), env.opts.autoescape);
output += "|";
output += runtime.suppressValue(runtime.memberLookup((t_8),"text"), env.opts.autoescape);
output += "\">\n                        <span class=\"comet-list-group__row-label\">\n                            ";
output += runtime.suppressValue((runtime.memberLookup((t_8),"dim_text")?runtime.memberLookup((t_8),"dim_text"):""), env.opts.autoescape);
output += " ";
output += runtime.suppressValue(runtime.memberLookup((t_8),"text"), env.opts.autoescape);
output += "\n                        </span>\n                    </a>\n                </li>\n            ";
;
}
}
frame = frame.pop();
output += "\n        </ul>\n        <button class=\"comet-button comet-flyout__close-button comet-button--icon-only js-comet-flyout__close-button\">\n            <svg class=\"comet-flyout__close-button__icon comet-icon--l\" focusable=\"false\" aria-hidden=\"true\">\n                <use xlink:href=\"";
output += runtime.suppressValue((lineno = 34, colno = 47, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "edde_static"), "edde_static", context, ["comet/2.x/comet.svg#x"])), env.opts.autoescape);
output += "\"></use>\n            </svg>\n            <span class=\"comet-screenreader-only\">Close Menu</span>\n        </button>\n    </div>\n</div>\n\n";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};

})();
})();

define("common/components/edde/templates/nav/includes/product_bar/_flyout_menu", function(){});

