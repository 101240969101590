define('common/components/product-bar/views/product-bar',[
        'app',
        '@discoveryedu/platform-components',
        'common/components/vue-wrapper/helpers/mount-vue-component',
        'njs!common/components/edde/templates/nav/includes/2019_global_nav/_global_nav(params:tmpl=edde/nav/includes/2019_global_nav/_global_nav.html)',
        'njs!common/components/edde/templates/nav/includes/2019_global_nav/_waffle(params:tmpl=edde/nav/includes/2019_global_nav/_waffle.html)',
        'njs!common/components/edde/templates/nav/includes/quicklist/_quicklist_spinner(params:tmpl=edde/nav/includes/quicklist/_quicklist_spinner.html)',
        'njs!common/components/edde/templates/nav/includes/quicklist/_quicklist_button(params:tmpl=edde/nav/includes/quicklist/_quicklist_button.html)',
        'njs!common/components/edde/templates/nav/includes/product_bar/_breadcrumbs(params:tmpl=edde/nav/includes/product_bar/_breadcrumbs.html)',
        'njs!common/components/edde/templates/nav/includes/product_bar/_user_context_dropdown(params:tmpl=edde/nav/includes/product_bar/_user_context_dropdown)',
        'njs!common/components/edde/templates/nav/includes/quicklist/_quicklist_panel(params:tmpl=edde/nav/includes/quicklist/_quicklist_panel)',
        'njs!common/components/edde/templates/nav/includes/product_bar/_multisite(params:tmpl=edde/nav/includes/product_bar/_multisite.html)',
        'njs!common/components/edde/templates/nav/includes/product_bar/_flyout_menu(params:tmpl=edde/nav/includes/product_bar/_flyout_menu.html)',
        'njs!common/components/edde/templates/nav/includes/product_bar/_flyout_trigger(params:tmpl=edde/nav/includes/product_bar/_flyout_trigger.html)',
    ], function (app, PlatformComponents, mountVueComponent, template, waffleTemplate, spinnerTemplate, quicklistButton) {
        "use strict";

        return Mn.LayoutView.extend({

            "template": template,

            "searchVisibleClass": "comet-page-shell__search-visible",

            "channels": {
                "learnRouter": Backbone.Radio.channel('learn-router')
            },

            "events": {
                "keydown @ui.searchField": "testForEnterPressForTracking",
                "click @ui.toggleSearchBtn": "toggleSearch"
            },

            "ui": {
                "searchField": ".comet-product-bar__search-input",
                "toggleSearchBtn": ".js-comet-product-bar__toggle-search"
            },

            "el": ".comet-product-bar-container",

            "initialize": function(opts){

                // if this is a learn router page, attach this listener
                // so this view is redrawn when the current page changes
                if (opts && opts.navigation) {
                    this.listenTo(opts.navigation, {
                        "change": this.render
                    });
                }
                this.$pageShell = $(".comet-page-shell");
                if (!this.$pageShell.length) {
                    // this is a "newer" legacy product bar
                    this.$pageShell = $("#scroll-content");
                }

                // If the page contains new Breadcrumbs and Nav switcher, close the search box
                if (this.$("#comet-page-shell__product-well").hasClass("comet-page-shell__product-well--contains-breadcrumb-nav")) {
                    this.$(".comet-page-shell").removeClass("comet-page-shell__search-visible");
                }

                this.listenTo(this.channels.learnRouter, {
                    'document:click': this.onClickDocument
                });
            },

            "onRender": function(){
                var $searchBox = this.$("#search-box-js");
                var user = app.initData.get('user');

                if ($searchBox.length) {
                    this.searchBox = mountVueComponent(
                        PlatformComponents.SearchBox,
                        "#search-box-js",
                        {
                            "additonalParams": $searchBox.data("additionalParams") || [],
                            "isSearchOpen": $searchBox.data("visibleOnLoad") === true,
                            "suggestionTypes": app.initData.get("suggestion_types"),
                            "query": $searchBox.data("query"),
                            "usePushState": $searchBox.data("usePushState") === true
                        },
                        void 0,
                        function(vueApp) {
                            vueApp.config.globalProperties.$windowLocation = window.location;
                        }
                    );
                }

                this.$(".comet-product-bar__action--profile .comet-dropdown-trigger").one("click.avatar", _.bind(this.onProfileOpen, this));

                $("#more-links-dropdown-trigger").one("click.loadWaffle", _.bind(this.onWaffleOpen, this));

                this.checkForBreadcrumbs();

                // qLAssets will be null if quicklist has not loaded yet.
                var qLAssets = Backbone.Radio.channel('quickmenu:add').request('assets');
                if (qLAssets) {
                    this.$(".comet-product-bar__action--quicklist").replaceWith(quicklistButton({ "count": qLAssets.length }));
                }

                if (window.self !== window.top) {
                    this.$('.comet-product-bar__profile-dropdown-sign-out-row')
                        .addClass('comet-product-bar__profile-dropdown-sign-out-row--hidden');
                }

                if (window.location !== window.parent.location && user.is_impersonation) {
                    var unimpersonateURL = new URL(this.$('#unimpersonate-link').attr('href'));
                    unimpersonateURL.searchParams.set('embedded', 'True');

                    this.$('#unimpersonate-link').attr({
                        'rel': 'opener',
                        'target': '_blank',
                        'href': unimpersonateURL.toString()
                    });

                    window.addEventListener('message', this.receiveMessage, false);
                }
            },

            "onClickDocument": function($etarget){
                this.hideSearchBoxIfVisible($etarget);
            },

            "onProfileOpen": function(){
                var $avatar = this.$(".comet-list-group__avatar"),
                    src = $avatar.data("src");
                if (src) {
                    $avatar.replaceWith($('<img />', {
                        "src": src,
                        "alt": "",
                        "class": $avatar.attr("class"),
                    }).load(function(){
                        $(this).addClass("comet-list-group__avatar--loaded");
                    }));
                }
            },

            "onWaffleOpen": function(e){
                var url = $(e.currentTarget).data("apiUrl");
                this.$(".comet-dropdown-menu--dex-global-nav").html(spinnerTemplate());

                var ajaxSettings = {
                    "dataType": "json",
                    "success": _.bind(function(data){
                        data.navigation.grid = data.navigation.grid.map(function(item) {
                            item.target = false;

                            if (item.nav_id === "MUS:SPIRAL") {
                                item.target = '_blank';
                            }

                            return item;
                        });

                        var initData = app.initData.toJSON();

                        var myContentEnabled = initData.product_settings && initData.product_settings.ENABLEMYCONTENT;
                        var classroomsEnabled = initData.product_settings && initData.product_settings.CLASSROOMMANAGER;

                        var disabledConditions = {
                            'MUS:MCON': !myContentEnabled,
                            'MUS:CLASSROOMS': !classroomsEnabled,
                        };

                        data.navigation.grid = data.navigation.grid.filter(function(tile) {
                            return !disabledConditions[tile.nav_id];
                        });

                        this.$(".comet-dropdown-menu--dex-global-nav").html(waffleTemplate({
                            "page_data": data,
                            "init_data": initData
                        }));
                    }, this)
                };

                if (url) {
                    ajaxSettings.crossDomain = true;
                    ajaxSettings.xhrFields = {
                        'withCredentials': true
                    };
                } else {
                    url = "/apiproxy/v1/navigation/waffle/data";
                }

                ajaxSettings.url = url;

                $.ajax(ajaxSettings);
            },

            "onFocusItem": function(el){
                var $searchField = $(this.ui.searchField),
                    $currentTarget = $(el.currentTarget),
                    autoSuggestValue = $currentTarget.text() && $currentTarget.text().trim();

                $searchField.val(autoSuggestValue || '');
                $searchField.attr("aria-activedescendant", $currentTarget.attr("id"));
            },

            "updateSearchSuggest": function(e){
                var $searchField = $(this.ui.searchField),
                    value = $searchField.val();

                if (value.length >= 2 && value !== this.lastSearchSuggestTerm) {

                    // if search suggest dropdown is closed, open it
                    if (!$searchField.hasClass("comet-dropdown-trigger--menu-visible")) {
                        $searchField.trigger("click");
                    }

                    if (this.searchSuggestXHR && this.searchSuggestXHR.readystate !== 4){
                        this.searchSuggestXHR.abort();
                    }

                    this.lastSearchSuggestTerm = value;

                    this.searchSuggestXHR = this.searchSuggestModel.fetch({
                        "data": {
                            "text": value
                        }
                    });
                }
            },

            "testForEnterPressForTracking": function(e){
                if (e.keyCode === 13){
                    this.$(".comet-product-bar__search-button").data("gal", "Search|Enter");
                }
            },

            "hideSearchBoxIfVisible": function($etarget){
                if (!this.isSearchBoxTrigger($etarget)) {
                    this.$pageShell.removeClass(this.searchVisibleClass);
                }
            },

            "isSearchBoxTrigger": function($etarget){
                return $etarget.hasClass("comet-product-bar__search-box-wrapper") || $etarget.closest(".comet-product-bar__search-box-wrapper").length > 0 || $etarget.hasClass("js-comet-product-bar__toggle-search") || $etarget.closest(".js-comet-product-bar__toggle-search").length > 0;
            },

            "toggleSearch": function(event){
                if (this.$pageShell.hasClass(this.searchVisibleClass)) {
                    this.$pageShell.removeClass(this.searchVisibleClass);
                } else {
                    this.$pageShell.addClass(this.searchVisibleClass);
                    $(event.target).closest('.comet-product-bar__action').find('.comet-product-bar__search-input').focus();
                }
            },

            "checkForBreadcrumbs": function(){
                if (this.$(".comet-breadcrumb--2018").length) {
                    $(".comet-page-shell__product-well").addClass("comet-page-shell__product-well--contains-breadcrumb-nav");
                }
            },

            "receiveMessage": function(event) {
                if (
                    event.data
                    && typeof event.data === 'string'
                    && event.origin === window.paths.app_url
                ) {
                    var message = JSON.parse(event.data);
                    if (message.type === 'unimpersonate' && message.next) {
                        setTimeout(function() {
                            document.location.href = message.next;
                        }, 2000);
                    }
                }
            },

            "onBeforeDestroy": function(){
                this.$("#more-links-dropdown-trigger").off("click.loadWaffle");
                this.$(".comet-product-bar__action--profile .comet-dropdown-trigger").off("click.avatar");

                if (window.location !== window.parent.location) {
                    window.removeEventListener('message', this.receiveMessage, false);
                }
            }

        });
    }
);

