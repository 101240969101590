define('common/components/modals/views/save',[
    'common/components/modals/views/modal',
    'common/components/modals/templates/save'

], function(ModalView, template) {
    'use strict';

    return ModalView.extend({

        "channel": Backbone.Radio.channel('quickmenu'),

        "footer": {
            "dismiss": "Cancel",
            "save": "Add"
        },

        "header": {
            "title": "Save"
        },

        "template": template,

        "alertErrorMessage": function(err) {
            this.trigger('alert', {
                "message": err.message,
                "type": "error"
            });
        },

        "initialize": function(opts) {
            this.responseHTML = opts.responseHTML;
            this.listenTo(this.channel, {
                "error": this.alertErrorMessage,
                "success": this.dismiss
            });
        },

        "serializeData": function() {
            return {
                "responseHTML": this.responseHTML
            };
        },

        "onSave": function() {
            this.channel.trigger('save');
        }

    });

});

