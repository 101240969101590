define('common/components/learn-router/router',[], function() {
    'use strict';

    return Marionette.AppRouter.extend({

        // GUID regex
        "guidRgx": /[a-f\d]{8}-(?:[a-f\d]{4}-){3}[a-f\d]{12}/,

        // ID regex
        // match only numbers just before a trailing-slash `/` OR query-string `?` OR the end-of-string `$`
        "idRgx": /[\d]+(?:(?=[\/\?])|$)/,

        // matches any alphanumeric, with underscores, periods or hyphens
        "qPathRgx": /[a-zA-Z0-9._-]*/,

        // matches any alphanumeric, with underscores, periods or hyphens
        // and captures the value to pass to loadApp
        "qPathActionRgx": /([a-zA-Z0-9._-]*)/,


        "initialize": function(){

            _.each([

                /*
                    like: learn/search

                    passes: action = ""

                    learn/:appName
                */
                new RegExp("^learn[A-z_]*/" + this.qPathActionRgx.source, "i"),

                /*
                    like: learn/assessment-builder/create

                    passes: action = "create"

                    learn/:appName/:action
                */
                new RegExp("^learn[A-z_]*/" + this.qPathActionRgx.source + "/" + this.qPathActionRgx.source, "i"),

                /*
                    like: /learn/orders/stem/school-confirmation

                    passes: action = "school-confirmation"

                    learn/:appName/stem/:action
                */
                new RegExp("^learn[A-z_]*/" + "(orders)/stem/" + this.qPathActionRgx.source, "i"),
                new RegExp("^learn[A-z_]*/" + "(welcome)/2.0/" + this.qPathActionRgx.source, "i"),

                /*
                    like learn/videos/guid

                    passes: action = undefined

                    learn/:appName/guid
                */
                new RegExp("^learn[A-z_]*/" + this.qPathActionRgx.source + "/" + this.guidRgx.source, "i"),

                /*
                    like learn/assessment-builder/search/guid

                    passes: action = "search"

                    learn/:appName/:action/ -> guid
                */
                new RegExp("^learn[A-z_]*/" + this.qPathActionRgx.source + "/" + this.qPathActionRgx.source + "/" +
                    this.guidRgx.source, "i"),

                /*
                    like learn/assessment-builder/search/guid/name

                    passes: action = "name"

                    learn/:appName/:action/ -> guid/name
                */

                new RegExp("^learn[A-z_]*/" + this.qPathActionRgx.source + "/" + this.qPathRgx.source + "/" +
                    this.guidRgx.source + "/" + new RegExp("(name|resources|grid|toc|overview)").source, "i"),

                /*
                    like learn/assessment/attempt/attempt-id

                    passes: action = "attempt"

                    learn/:appName/:action/ -> id
                */
                new RegExp("^learn[A-z_]*/" + this.qPathActionRgx.source + "/" + this.qPathActionRgx.source + "/" +
                    this.idRgx.source, "i"),

                /*
                    like learn/item-builder/guid/new

                    passes: action = "new"

                    learn/:appName/ -> guid/:action
                */
                new RegExp("^learn[A-z_]*/" + this.qPathActionRgx.source + "/" + this.guidRgx.source + "/" +
                    this.qPathActionRgx.source, "i"),

                /*
                    like learn/techbook/units/guid/concepts/guid

                    passes: action = "concepts"

                    learn/:appName/:action/ -> guid/:deepAction/guid
                */
                new RegExp("^learn[A-z_]*/" + this.qPathActionRgx.source + "/" + this.qPathRgx.source + "/" +
                    this.guidRgx.source + "/" + this.qPathActionRgx.source + "/" + this.guidRgx.source, "i"),

                /*
                    like learn/assessment/guid/assignment/guid/attempt

                    passes: action = "attempt"

                    learn/:appName/:action/guid/ -> :deepAction/guid/:reallyDeepAction
                */
                new RegExp("^learn[A-z_]*/" + this.qPathActionRgx.source + "/" + this.guidRgx.source + "/" +
                    this.qPathRgx.source + "/" + this.guidRgx.source + "/" + this.qPathActionRgx.source, "i"),

                /*
                    like learn/techbook/units/guid/concepts/guid/lesson

                    passes: action = "concepts#lesson"

                    learn/:appName/:action/guid/ -> :deepAction/guid/:reallyDeepAction
                */
                new RegExp("^learn[A-z_]*/" + this.qPathActionRgx.source + "/" + this.qPathRgx.source + "/" + this.guidRgx.source + "/" +
                    this.qPathActionRgx.source + "/" + this.guidRgx.source + "/" + this.qPathActionRgx.source, "i"),

                /*
                    like learn/techbook/units/guid/concepts/guid/lesson/standards
                    passes: action = "concepts#standards"
                    learn/:appName/:action/guid/ -> :deepAction/guid/ -> :reallyReallyDeepAction/:reallyDeepAction
                */
                new RegExp("^learn[A-z_]*/" + this.qPathActionRgx.source + "/" + this.qPathRgx.source + "/" + this.guidRgx.source + "/" +
                    this.qPathActionRgx.source + "/" + this.guidRgx.source + "/" +
                    this.qPathRgx.source + "/" + this.qPathActionRgx.source, "i"),

                /*
                    like learn/streaming/standards/course/guid/documents/Learning%20Standards

                    passes: action = "documents"
                    learn/:appName/:action/ -> :deepAction/guid/ -> :reallyReallyDeepAction/path
                */
                new RegExp("^learn[A-z_]*/" + this.qPathActionRgx.source + "/" + this.qPathRgx.source + "/" +
                    this.qPathRgx.source + "/" + this.guidRgx.source + "/" +
                    this.qPathActionRgx.source + "/" + this.qPathRgx.source, "i"),

                /*
                    like learn/streaming/standards/c/ZZ/region/id

                    passes: action = "region"

                    learn/:appName/:action/ -> path/path/:deepAction/id
                */

                new RegExp("^learn[A-z_]*/" + this.qPathActionRgx.source + "/" + this.qPathRgx.source + "/" +
                    this.qPathRgx.source + "/" + this.qPathRgx.source + "/" + this.qPathActionRgx.source + "/" + this.idRgx.source, "i"),
                /*
                    like learn/techbook/units/guid/concepts/guid/tabs/guid

                    passes: action = "concepts#tabs"

                    learn/:appName/:action/ -> guid/:deepAction/guid -> :reallyDeepAction/guid
                */
                new RegExp("^learn[A-z_]*/" + this.qPathActionRgx.source + "/" + this.qPathRgx.source + "/" +
                    this.guidRgx.source + "/" + this.qPathActionRgx.source + "/" + this.guidRgx.source + "/" +
                    this.qPathActionRgx.source + "/" + this.guidRgx.source, "i"),

                /*
                    like learn/techbook/units/guid/concepts/guid/tabs/guid/pages/guid

                    passes: action = "concepts#pages"

                    learn/:appName/:action/ -> guid/:deepAction/guid -> :reallyDeepAction/guid/ -> :reallyReallyDeepAction/guid
                */
                new RegExp("^learn[A-z_]*/" + this.qPathActionRgx.source + "/" + this.qPathRgx.source + "/" +
                    this.guidRgx.source + "/" + this.qPathActionRgx.source + "/" + this.guidRgx.source + "/" +
                    this.qPathRgx.source + "/" + this.guidRgx.source + "/" +
                    this.qPathActionRgx.source + "/" + this.guidRgx.source, "i"),

                /*
                    like learn/techbook/units/guid/concepts/guid/tabs/guid/pages/guid/language/:path

                    passes: action = "concepts#language"

                    learn/:appName/:action/ -> guid/:deepAction/guid -> :reallyDeepAction/guid/ ->
                    :reallyReallyDeepAction/guid -> :reallyReallyReallyDeepAction/path
                */
                new RegExp("^learn[A-z_]*/" + this.qPathActionRgx.source + "/" + this.qPathRgx.source + "/" +
                    this.guidRgx.source + "/" + this.qPathActionRgx.source + "/" + this.guidRgx.source + "/" +
                    this.qPathRgx.source + "/" + this.guidRgx.source + "/" +
                    this.qPathRgx.source + "/" + this.guidRgx.source +
                    this.qPathActionRgx.source + "/" + this.qPathRgx.source, "i"),

                /*
                    exact learn/techbook/print/*

                    passes: action = "print"

                    learn/:appName/:action/
                */
                new RegExp("^learn[A-z_]*/(techbook)/(print)/", "i"),

                /*
                    like learn/assessment-results/assessment/guid/coach

                    passes: action = "coach"

                    learn/:appName/path/guid/:action
                */
                new RegExp("^learn[A-z_]*/(assessment-results)/" + this.qPathRgx.source + "/" + this.guidRgx.source + "/" +
                    this.qPathActionRgx.source, "i"),

                /*
                    like learn/assessment-results/assignment/guid/attempt/id

                    passes: action = "assignment#attempt"

                    learn/:appName/:action/guid/:deepAction/id
                */
                new RegExp("^learn[A-z_]*/(assessment-results)/" + this.qPathActionRgx.source + "/" + this.guidRgx.source + "/" +
                    this.qPathActionRgx.source + "/" + this.idRgx.source, "i"),

                /*
                    like learn/assessment-results/assessment/guid/coach/attempt/id

                    passes: action = "coach#attempt"

                    learn/:appName/path/guid/:action/:deepAction/id
                */
                new RegExp("^learn[A-z_]*/(assessment-results)/" + this.qPathRgx.source + "/" + this.guidRgx.source + "/" +
                    this.qPathActionRgx.source + "/" + this.qPathActionRgx.source + "/" + this.idRgx.source, "i"),

                /*
                    like learn/dashboard/courses/guid/badges

                    passes: action = "badges"

                    learn/:appName/path/guid/:action
                */
                new RegExp("^learn[A-z_]*/(dashboard)/" + this.qPathRgx.source + "/" + this.guidRgx.source + "/(badges)", "i"),

                // learn/classrooms/google/<guid>/manage  -> learn/:appName/path/guid/:action
                new RegExp("^learn[A-z_]*/(classrooms)/" + this.qPathActionRgx.source + "/" + this.guidRgx.source + "/" + this.qPathActionRgx.source, "i"),

                // learn/classrooms/classroom/<guid>/manage
                new RegExp("^learn[A-z_]*/(classrooms)/" + this.qPathActionRgx.source + this.guidRgx.source + "/" + this.qPathActionRgx.source, "i"),

                // learn/classrooms/classroom/<guid>/manage/students
                new RegExp("^learn[A-z_]*/(classrooms)/" + this.qPathActionRgx.source + this.guidRgx.source + "/" + this.qPathRgx.source + "/" + this.qPathActionRgx.source, "i"),

                // learn/classrooms/classroom/<guid>/student/guid
                new RegExp("^learn[A-z_]*/(classrooms)/" + this.qPathActionRgx.source + "/" + this.guidRgx.source + "/" + this.qPathActionRgx.source + "/" + this.guidRgx.source, "i"),

                // learn/reporting/techbook/assessments/<formative|summative>/course/<guid>
                new RegExp("^learn[A-z_]*/(reporting)/techbook/assessments/" + this.qPathActionRgx.source + "/" + this.qPathRgx.source + "/" + this.guidRgx.source, "i")

            ], function(route, i){
                this.appRoute(route, "loadApp");
            }, this);
        }
    });
});

