define('common/components/comet/core-behaviors',[
    'common/components/comet/behaviors/comet-dropdown',
    'common/components/comet/behaviors/comet-toggletip',
    'common/components/comet/behaviors/action-menu',
    'common/components/comet/behaviors/actions',
    'common/components/comet/behaviors/inactivity'
    ], function(CometDropDownBehavior, CometToggleTipBehavior, ActionMenuBehavior, ActionsBehavior, InactivityBehavior) {
    'use strict';

    return {
        "CometDropDown": {
            "behaviorClass": CometDropDownBehavior,
        },

        "CometToggleTipBehavior": {
            "behaviorClass": CometToggleTipBehavior
        },

        "ActionMenu": {
            "behaviorClass": ActionMenuBehavior,
        },

        "ActionsBehavior": {
            "behaviorClass": ActionsBehavior
        },

        "InactivityBehavior": {
            "behaviorClass": InactivityBehavior
        }
    };

});

