(function() {(window.nunjucksPrecompiled = window.nunjucksPrecompiled || {})["edde/nav/includes/2019_global_nav/_global_nav.html"] = (function() {
function root(env, context, frame, runtime, cb) {
var lineno = 0;
var colno = 0;
var output = "";
try {
var parentTemplate = null;
if(runtime.contextOrFrameLookup(context, frame, "init_data") && runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "init_data")),"product_code")) {
output += " ";
output += "\n    ";
var t_1;
t_1 = env.getFilter("lower").call(context, runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "init_data")),"product_code"));
frame.set("product_code", t_1, true);
if(frame.topLevel) {
context.setVariable("product_code", t_1);
}
if(frame.topLevel) {
context.addExport("product_code", t_1);
}
output += "\n\n    ";
if(runtime.contextOrFrameLookup(context, frame, "product_code") == "detb" || runtime.contextOrFrameLookup(context, frame, "product_code") == "dsc" || runtime.contextOrFrameLookup(context, frame, "product_code") == "dsce") {
output += " ";
output += "\n        ";
var t_2;
t_2 = "science";
frame.set("product", t_2, true);
if(frame.topLevel) {
context.setVariable("product", t_2);
}
if(frame.topLevel) {
context.addExport("product", t_2);
}
output += "\n    ";
;
}
else {
if(runtime.contextOrFrameLookup(context, frame, "product_code") == "demb") {
output += " ";
output += "\n        ";
var t_3;
t_3 = "math";
frame.set("product", t_3, true);
if(frame.topLevel) {
context.setVariable("product", t_3);
}
if(frame.topLevel) {
context.addExport("product", t_3);
}
output += "\n    ";
;
}
else {
if(runtime.contextOrFrameLookup(context, frame, "product_code") == "stem") {
output += " ";
output += "\n        ";
var t_4;
t_4 = "stem";
frame.set("product", t_4, true);
if(frame.topLevel) {
context.setVariable("product", t_4);
}
if(frame.topLevel) {
context.addExport("product", t_4);
}
output += "\n    ";
;
}
else {
if(runtime.contextOrFrameLookup(context, frame, "product_code") == "socs") {
output += "\n        ";
var t_5;
t_5 = "social-studies";
frame.set("product", t_5, true);
if(frame.topLevel) {
context.setVariable("product", t_5);
}
if(frame.topLevel) {
context.addExport("product", t_5);
}
output += " ";
output += "\n    ";
;
}
;
}
;
}
;
}
output += "\n";
;
}
else {
output += "\n    ";
var t_6;
t_6 = "streaming";
frame.set("product", t_6, true);
if(frame.topLevel) {
context.setVariable("product", t_6);
}
if(frame.topLevel) {
context.addExport("product", t_6);
}
output += "\n";
;
}
output += "\n\n\n";
var t_7;
t_7 = ["DEMB","DETB","SOCS","STEM","DSCE","DSC","DHC"];
frame.set("white_listed_global_nav_items", t_7, true);
if(frame.topLevel) {
context.setVariable("white_listed_global_nav_items", t_7);
}
if(frame.topLevel) {
context.addExport("white_listed_global_nav_items", t_7);
}
output += "\n\n";
var t_8;
t_8 = {"DEMB": {"course": (lineno = 32, colno = 23, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "_"), "_", context, ["Math"])),"type": (lineno = 33, colno = 21, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "_"), "_", context, ["Techbook"]))},"DETB": {"course": (lineno = 36, colno = 23, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "_"), "_", context, ["Science"])),"type": (lineno = 37, colno = 21, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "_"), "_", context, ["Techbook"]))},"SOCS": {"course": (lineno = 40, colno = 23, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "_"), "_", context, ["Social Studies"])),"type": (lineno = 41, colno = 21, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "_"), "_", context, ["Techbook"]))},"STEM": {"course": (lineno = 44, colno = 23, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "_"), "_", context, ["STEM"])),"type": (lineno = 45, colno = 21, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "_"), "_", context, ["Connect"]))},"DSCE": {"course": (lineno = 48, colno = 23, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "_"), "_", context, ["Science"])),"type": (lineno = 49, colno = 21, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "_"), "_", context, ["Elementary"]))},"DSC": {"course": (lineno = 52, colno = 23, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "_"), "_", context, ["Science"])),"type": (lineno = 53, colno = 21, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "_"), "_", context, ["Middle School"]))},"DHC": {"course": (lineno = 56, colno = 23, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "_"), "_", context, ["Health"]))}};
frame.set("global_nav_items_meta", t_8, true);
if(frame.topLevel) {
context.setVariable("global_nav_items_meta", t_8);
}
if(frame.topLevel) {
context.addExport("global_nav_items_meta", t_8);
}
output += "\n\n\n<div class=\"comet-product-bar-container comet-product-bar-container--dex\">\n\n    <div class=\"comet-product-bar js-comet-product-bar comet-product-bar__search-revealed-static comet-product-bar--theme-product-";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "product"), env.opts.autoescape);
output += " comet-product-bar--dex\">\n\n        <a id=\"comet-navigation\" href=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "page_data")),"navigation")),"home_url"), env.opts.autoescape);
output += "\" class=\"comet-global-nav-bar__header comet-global-nav-bar__header--dex\" title=\"";
output += runtime.suppressValue((lineno = 67, colno = 158, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "_"), "_", context, ["Homepage"])), env.opts.autoescape);
output += "\">\n            <div class=\"comet-global-nav-bar__brand-logo-wrap\">\n                <img alt=\"Discovery Education\" class=\"comet-global-nav-bar__brand-logo-small\" src=\"";
output += runtime.suppressValue((lineno = 69, colno = 113, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "core_static"), "core_static", context, ["common/components/product-bar/images/de-logo-2020.png"])), env.opts.autoescape);
output += "\">\n            </div>\n        </a>\n\n        ";
if(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "page_data")),"navigation")),"logo")) {
output += "\n            ";
if(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "page_data")),"navigation")),"logo") != "streaming-plus") {
output += "\n                <a class=\"comet-product-bar__product-title\" href=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "page_data")),"navigation")),"product_url"), env.opts.autoescape);
output += "\">\n                    <svg class=\"comet-product-bar__product-title-icon\" aria-hidden=\"true\" focusable=\"false\">\n                        <use xlink:href=\"";
output += runtime.suppressValue((lineno = 77, colno = 55, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "edde_static"), "edde_static", context, ["comet/2.x/comet.svg#" + runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "page_data")),"navigation")),"logo")])), env.opts.autoescape);
output += "\"></use>\n                    </svg>\n                    <span class=\"comet-screenreader-only\">";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "page_data")),"navigation")),"product_name"), env.opts.autoescape);
output += "</span>\n                </a>\n            ";
;
}
output += "\n        ";
;
}
else {
if(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "page_data")),"navigation")),"product_name")) {
output += "\n            <a class=\"comet-product-bar__product-title\" href=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "page_data")),"navigation")),"product_url"), env.opts.autoescape);
output += "\">";
output += runtime.suppressValue((lineno = 83, colno = 106, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "_"), "_", context, [runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "page_data")),"navigation")),"product_name")])), env.opts.autoescape);
output += "</a> ";
output += "\n        ";
;
}
;
}
output += "\n\n        <!-- START OF BREADCRUMBS 2018 -->\n        ";
var tasks = [];
tasks.push(
function(callback) {
env.getTemplate("edde/nav/includes/product_bar/_breadcrumbs.html", false, "edde/nav/includes/2019_global_nav/_global_nav.html", false, function(t_10,t_9) {
if(t_10) { cb(t_10); return; }
callback(null,t_9);});
});
tasks.push(
function(template, callback){
template.render(context.getVariables(), frame, function(t_12,t_11) {
if(t_12) { cb(t_12); return; }
callback(null,t_11);});
});
tasks.push(
function(result, callback){
output += result;
callback(null);
});
env.waterfall(tasks, function(){
output += "\n        <!-- END OF BREADCRUMBS -->\n\n        ";
if(runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "page_data")),"navigation")),"user")),"show_search_box")) {
output += "\n            <div id=\"search-box-js\"\n                ";
if(runtime.contextOrFrameLookup(context, frame, "page_data")) {
output += "\n                    ";
if(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "page_data")),"options") && runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "page_data")),"options")),"term")) {
output += "\n                        data-query=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "page_data")),"options")),"term"), env.opts.autoescape);
output += "\"\n                        data-use-push-state=\"true\"\n                    ";
;
}
output += "\n                    ";
if(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "page_data")),"navigation") && !runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "page_data")),"navigation")),"breadcrumbs")) {
output += "\n                        data-visible-on-load=\"true\"\n                    ";
;
}
output += "\n                    ";
if(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "page_data")),"search_navigation") && runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "page_data")),"search_navigation")),"additional_params")) {
output += "\n                        data-additional-params='";
output += runtime.suppressValue(env.getFilter("tojson").call(context, runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "page_data")),"search_navigation")),"additional_params")), env.opts.autoescape);
output += "'\n                    ";
;
}
output += "\n                ";
;
}
output += "\n            ></div>\n        ";
;
}
output += "\n\n\n        <ul class=\"comet-product-bar__actions\">\n\n            ";
if(runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "page_data")),"navigation")),"user")),"show_search_box")) {
output += "\n                <li class=\"comet-product-bar__action\">\n                    <button id=\"search-dropdown-trigger\" class=\"comet-button comet-button--medium comet-product-bar__action-link comet-product-bar__action-link--search\" aria-expanded=\"false\">\n\n                        <div class=\"comet-product-bar__action__popover comet-popover comet-popover--bottom visible\" aria-hidden=\"true\">\n                            <div class=\"comet-popover__nub\"></div>\n                            ";
output += runtime.suppressValue((lineno = 116, colno = 32, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "_"), "_", context, ["Search"])), env.opts.autoescape);
output += "\n                        </div>\n                        <svg class=\"comet-product-bar__action-icon\" focusable=\"false\" aria-hidden=\"true\">\n                            <use xlink:href=\"";
output += runtime.suppressValue((lineno = 119, colno = 59, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "edde_static"), "edde_static", context, ["comet/2.x/comet.svg#search"])), env.opts.autoescape);
output += "\"></use>\n                            <span class='comet-screenreader-only'>";
output += runtime.suppressValue((lineno = 120, colno = 70, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "_"), "_", context, ["Search"])), env.opts.autoescape);
output += "</span>\n                        </svg>\n\n                    </button>\n                </li>\n            ";
;
}
output += "\n\n            ";
if(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "init_data")),"product_settings") && !runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "init_data")),"product_settings")),"HIDEALLASSETACTIONS")) {
output += "\n                ";
if(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "init_data")),"quicklist")) {
output += "\n                    <!-- START OF QUICKLIST BUTTON -->\n                    ";
var tasks = [];
tasks.push(
function(callback) {
env.getTemplate("edde/nav/includes/quicklist/_quicklist_button.html", false, "edde/nav/includes/2019_global_nav/_global_nav.html", false, function(t_14,t_13) {
if(t_14) { cb(t_14); return; }
callback(null,t_13);});
});
tasks.push(
function(template, callback){
template.render(context.getVariables(), frame, function(t_16,t_15) {
if(t_16) { cb(t_16); return; }
callback(null,t_15);});
});
tasks.push(
function(result, callback){
output += result;
callback(null);
});
env.waterfall(tasks, function(){
output += "\n                    <!-- END OF QUICKLIST BUTTON -->\n                ";
});
}
output += "\n\n                <li class=\"comet-product-bar__action comet-product-bar__action--global-nav\">\n                    <button id=\"more-links-dropdown-trigger\" class=\"comet-button comet-button--small comet-product-bar__action-link comet-product-bar__action-link--more-links comet-dropdown-trigger js-comet-dropdown-trigger comet-button--icon-only\" aria-expanded=\"false\" data-api-url=\"";
output += runtime.suppressValue((lineno = 135, colno = 298, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "get_DE_url"), "get_DE_url", context, ["api"])), env.opts.autoescape);
output += "/v1/navigation/waffle/data\">\n                        <svg class=\"comet-product-bar__action-icon\" focusable=\"false\" aria-hidden=\"true\">\n                            <use xlink:href=\"";
output += runtime.suppressValue((lineno = 137, colno = 59, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "edde_static"), "edde_static", context, ["comet/2.x/comet.svg#waffle"])), env.opts.autoescape);
output += "\"></use>\n                            <span class='comet-screenreader-only'>";
output += runtime.suppressValue((lineno = 138, colno = 70, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "_"), "_", context, ["More"])), env.opts.autoescape);
output += "</span>\n                        </svg>\n                        <div class=\"comet-product-bar__action__popover comet-popover comet-popover--bottom visible\" aria-hidden=\"true\">\n                            <div class=\"comet-popover__nub\"></div>\n                            ";
output += runtime.suppressValue((lineno = 142, colno = 32, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "_"), "_", context, ["More"])), env.opts.autoescape);
output += "\n                        </div>\n                    </button>\n\n                    <div class=\"comet-dropdown-menu comet-popover comet-dropdown-menu--dex-global-nav\" aria-hidden=\"true\" aria-labelledby=\"more-links-dropdown-trigger\" role=\"region\">\n                    </div>\n\n                </li>\n            ";
;
}
output += "\n\n            <li class=\"comet-product-bar__action comet-product-bar__action--profile\">\n                <button id=\"profile-dropdown-trigger\" class=\"comet-button comet-button--small comet-product-bar__action-link comet-dropdown-trigger js-comet-dropdown-trigger\" aria-expanded=\"false\">\n                        ";
output += runtime.suppressValue((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "init_data")),"user")),"first_name") && env.getFilter("trim").call(context, runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "init_data")),"user")),"first_name"))?env.getFilter("first").call(context, env.getFilter("trim").call(context, runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "init_data")),"user")),"first_name"))):""), env.opts.autoescape);
output += runtime.suppressValue((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "init_data")),"user")),"last_name") && env.getFilter("trim").call(context, runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "init_data")),"user")),"last_name"))?env.getFilter("first").call(context, env.getFilter("trim").call(context, runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "init_data")),"user")),"last_name"))):""), env.opts.autoescape);
output += "\n                        ";
output += runtime.suppressValue((!runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "init_data")),"user")),"first_name") && !runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "init_data")),"user")),"last_name")?"DE":""), env.opts.autoescape);
output += "\n                    <div class=\"comet-product-bar__action__popover comet-popover comet-popover--bottom visible\" aria-hidden=\"true\">\n                        <div class=\"comet-popover__nub\"></div>\n                        ";
output += runtime.suppressValue((lineno = 158, colno = 28, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "_"), "_", context, ["Profile"])), env.opts.autoescape);
output += "\n                    </div>\n                </button>\n\n                ";
var tasks = [];
tasks.push(
function(callback) {
env.getTemplate("edde/nav/includes/product_bar/_user_context_dropdown.html", false, "edde/nav/includes/2019_global_nav/_global_nav.html", false, function(t_18,t_17) {
if(t_18) { cb(t_18); return; }
callback(null,t_17);});
});
tasks.push(
function(template, callback){
template.render(context.getVariables(), frame, function(t_20,t_19) {
if(t_20) { cb(t_20); return; }
callback(null,t_19);});
});
tasks.push(
function(result, callback){
output += result;
callback(null);
});
env.waterfall(tasks, function(){
output += "\n\n            </li>\n\n        </ul>\n\n\n    </div>\n\n    <!-- START OF FLYOUT MENU 2018 -->\n    ";
var t_21;
t_21 = "";
frame.set("dropdown_obj", t_21, true);
if(frame.topLevel) {
context.setVariable("dropdown_obj", t_21);
}
if(frame.topLevel) {
context.addExport("dropdown_obj", t_21);
}
output += "\n    ";
if(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "page_data")),"navigation") && runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "page_data")),"navigation")),"breadcrumbs")) {
output += "\n        ";
var t_22;
t_22 = env.getFilter("last").call(context, runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "page_data")),"navigation")),"breadcrumbs"));
frame.set("dropdown_obj", t_22, true);
if(frame.topLevel) {
context.setVariable("dropdown_obj", t_22);
}
if(frame.topLevel) {
context.addExport("dropdown_obj", t_22);
}
output += "\n    ";
;
}
output += "\n    ";
if(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "page_data")),"navigation")),"breadcrumbs") && runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "dropdown_obj")),"dropdown")) {
output += "\n        ";
var tasks = [];
tasks.push(
function(callback) {
env.getTemplate("edde/nav/includes/product_bar/_flyout_menu.html", false, "edde/nav/includes/2019_global_nav/_global_nav.html", false, function(t_24,t_23) {
if(t_24) { cb(t_24); return; }
callback(null,t_23);});
});
tasks.push(
function(template, callback){
template.render(context.getVariables(), frame, function(t_26,t_25) {
if(t_26) { cb(t_26); return; }
callback(null,t_25);});
});
tasks.push(
function(result, callback){
output += result;
callback(null);
});
env.waterfall(tasks, function(){
output += "\n    ";
});
}
output += "\n    <!-- END OF FLYOUT MENU 2018 -->\n\n</div>\n";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
})});
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};

})();
})();

define("common/components/edde/templates/nav/includes/2019_global_nav/_global_nav", function(){});

