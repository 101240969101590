(function() {(window.nunjucksPrecompiled = window.nunjucksPrecompiled || {})["edde/nav/includes/product_bar/_search_opt_in_out_banner.html"] = (function() {
function root(env, context, frame, runtime, cb) {
var lineno = 0;
var colno = 0;
var output = "";
try {
var parentTemplate = null;
output += "<div class=\"search-opt-in-out-banner\">\n    <p class=\"search-opt-in-out-banner\">";
output += runtime.suppressValue((lineno = 1, colno = 44, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "_"), "_", context, ["A new search experience is coming soon to DE Experience... Try it out now!"])), env.opts.autoescape);
output += "</p>\n    <fieldset class=\"nebula-fieldset\" id=\"search-opt-in-out-switch-container\"></fieldset>\n</div>\n<p aria-atomic=\"true\" aria-live=\"assertive\" id=\"search-opt-in-out-banner__sr-alert--switch-off\" class=\"nebula-screenreader-only search-opt-in-out-banner__sr-alert search-opt-in-out-banner__sr-alert--inactive\" role=\"alert\">";
output += runtime.suppressValue(("Loading original search experience"), env.opts.autoescape);
output += "</p>\n<p aria-atomic=\"true\" aria-live=\"assertive\" id=\"search-opt-in-out-banner__sr-alert--switch-on\" class=\"nebula-screenreader-only search-opt-in-out-banner__sr-alert search-opt-in-out-banner__sr-alert--inactive\" role=\"alert\">";
output += runtime.suppressValue(("Loading new search experience"), env.opts.autoescape);
output += "</p>\n";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};

})();
})();

define("common/components/edde/templates/nav/includes/product_bar/_search_opt_in_out_banner", function(){});

