(function() {(window.nunjucksPrecompiled = window.nunjucksPrecompiled || {})["edde/nav/includes/2019_global_nav/_waffle.html"] = (function() {
function root(env, context, frame, runtime, cb) {
var lineno = 0;
var colno = 0;
var output = "";
try {
var parentTemplate = null;
output += "\n<div class=\"comet-popover__nub\"></div>\n<div class=\"comet-list-group comet-theme--dark-background\">\n    <ul class=\"comet-cards waffle-grid\">\n\n        ";
frame = frame.push();
var t_3 = runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "page_data")),"navigation")),"grid");
if(t_3) {t_3 = runtime.fromIterator(t_3);
var t_2 = t_3.length;
for(var t_1=0; t_1 < t_3.length; t_1++) {
var t_4 = t_3[t_1];
frame.set("item", t_4);
frame.set("loop.index", t_1 + 1);
frame.set("loop.index0", t_1);
frame.set("loop.revindex", t_2 - t_1);
frame.set("loop.revindex0", t_2 - t_1 - 1);
frame.set("loop.first", t_1 === 0);
frame.set("loop.last", t_1 === t_2 - 1);
frame.set("loop.length", t_2);
output += "\n            <li class=\"comet-card dex-color-card dex-color-card--";
output += runtime.suppressValue(runtime.memberLookup((t_4),"card_color"), env.opts.autoescape);
output += "\">\n                <a class=\"comet-card__card-anchor\" href=\"";
output += runtime.suppressValue(runtime.memberLookup((t_4),"url"), env.opts.autoescape);
output += "\" ";
if(runtime.memberLookup((t_4),"target")) {
output += " target=\"_blank\" ";
;
}
output += ">\n                    <svg class=\"comet-icon--l dex-color-card-icon\" focusable=\"false\" aria-hidden=\"true\">\n                        <use xlink:href=\"";
output += runtime.suppressValue((lineno = 9, colno = 55, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "edde_static"), "edde_static", context, ["node_modules/@discoveryedu/nebula-icons/nebula.svg#" + runtime.memberLookup((t_4),"icon")])), env.opts.autoescape);
output += "\"></use>\n                    </svg>\n                    <div class=\"comet-card__copy dex-color-card__copy\">\n                        <p class=\"comet-card__title\">";
output += runtime.suppressValue((lineno = 12, colno = 57, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "_"), "_", context, [runtime.memberLookup((t_4),"name")])), env.opts.autoescape);
output += "</p> ";
output += "\n                    </div>\n                </a>\n            </li>\n        ";
;
}
}
frame = frame.pop();
output += "\n\n        <li class=\"comet-card dex-color-card comet-card--invisible\"></li>\n    </ul>\n\n    <ul class=\"comet-cards dex-color-card__product-cards\">\n\n        ";
frame = frame.push();
var t_7 = runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "page_data")),"navigation")),"products");
if(t_7) {t_7 = runtime.fromIterator(t_7);
var t_6 = t_7.length;
for(var t_5=0; t_5 < t_7.length; t_5++) {
var t_8 = t_7[t_5];
frame.set("item", t_8);
frame.set("loop.index", t_5 + 1);
frame.set("loop.index0", t_5);
frame.set("loop.revindex", t_6 - t_5);
frame.set("loop.revindex0", t_6 - t_5 - 1);
frame.set("loop.first", t_5 === 0);
frame.set("loop.last", t_5 === t_6 - 1);
frame.set("loop.length", t_6);
output += "\n\n            <li class=\"comet-card dex-color-card dex-color-card--product dex-color-card--product-";
output += runtime.suppressValue(env.getFilter("lower").call(context, runtime.memberLookup((t_8),"nav_id")), env.opts.autoescape);
output += "\">\n                <a class=\"comet-card__card-anchor\" href=\"";
output += runtime.suppressValue(runtime.memberLookup((t_8),"url"), env.opts.autoescape);
output += "\"";
if(env.getFilter("lower").call(context, runtime.memberLookup((t_8),"nav_id")) == "mysterysci") {
output += " target=\"_blank\"";
;
}
output += ">\n                    <div class=\"comet-card__copy dex-color-card__copy\">\n                        <p class=\"comet-card__title\">\n                            <span class=\"dex-color-card__product-course-name\">";
output += runtime.suppressValue(runtime.memberLookup((t_8),"name"), env.opts.autoescape);
output += "</span>\n                            ";
if(runtime.memberLookup((t_8),"type")) {
output += "\n                                <span class=\"dex-color-card__product-type-name\">";
output += runtime.suppressValue(runtime.memberLookup((t_8),"type"), env.opts.autoescape);
output += "</span>\n                            ";
;
}
output += "\n                        </p>\n                        <div class=\"dex-color-card__product-icon-container\">\n                            <svg class=\"comet-icon--l dex-color-card-icon\" focusable=\"false\" aria-hidden=\"true\">\n                                <use xlink:href=\"";
output += runtime.suppressValue((lineno = 36, colno = 63, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "edde_static"), "edde_static", context, ["comet/2.x/comet.svg#" + runtime.memberLookup((t_8),"icon")])), env.opts.autoescape);
output += "\"></use>\n                            </svg>\n                        </div>\n                    </div>\n                </a>\n            </li>\n        ";
;
}
}
frame = frame.pop();
output += "\n        <li class=\"comet-card dex-color-card dex-color-card--product dex-color-card--myde\">\n            <a href=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "page_data")),"navigation")),"home_url"), env.opts.autoescape);
output += "\" class=\"nebula-button nebula-button--l nebula-button--square nebula-button--ghost\">\n                <span class=\"nebula-button__text\">";
output += runtime.suppressValue((lineno = 45, colno = 54, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "_"), "_", context, ["Go to My DE"])), env.opts.autoescape);
output += "</span>\n            </a>\n        </li>\n    </ul>\n</div>\n";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};

})();
})();

define("common/components/edde/templates/nav/includes/2019_global_nav/_waffle", function(){});

