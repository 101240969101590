(function() {(window.nunjucksPrecompiled = window.nunjucksPrecompiled || {})["edde/nav/includes/product_bar/_flyout_trigger.html"] = (function() {
function root(env, context, frame, runtime, cb) {
var lineno = 0;
var colno = 0;
var output = "";
try {
var parentTemplate = null;
output += "<div class=\"comet-flyout-trigger__wrap navigation-flyout-trigger__wrap\">\n    <button class=\"comet-product-bar__breadcrumb-link comet-button comet-button--flat navigation-flyout-trigger comet-flyout-trigger js-comet-flyout-trigger\" id=\"breadcrumb-flyout-trigger\" data-open=\"false\" aria-expanded=\"false\">\n        <div class=\"comet-flyout-trigger__text-wrap navigation-flyout-trigger__wrap\">\n            ";
if(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "breadcrumb")),"truncated_text")) {
output += "\n                <span class=\"comet-product-bar__breadcrumb-truncated-text\" id=\"tm__bctrunctext_";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "loop")),"index"), env.opts.autoescape);
output += "\">\n                    ";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "breadcrumb")),"truncated_text"), env.opts.autoescape);
output += "\n                </span>\n            ";
;
}
output += "\n            ";
if(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "breadcrumb")),"dim_text")) {
output += "\n                <span class=\"comet-product-bar__breadcrumb-dim-text comet-flyout-trigger__label\" id=\"tm__bcdimtext_";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "loop")),"index"), env.opts.autoescape);
output += "\">\n                     ";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "breadcrumb")),"dim_text"), env.opts.autoescape);
output += "\n                </span>\n                <br>\n            ";
;
}
output += "\n            <span class=\"comet-product-bar__breadcrumb-text comet-flyout-trigger__title\" id=\"tm__bctext_";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "loop")),"index"), env.opts.autoescape);
output += "\">\n                ";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "breadcrumb")),"text"), env.opts.autoescape);
output += "\n            </span>\n        </div>\n        <svg class=\"comet-flyout-trigger__expand-icon comet-flyout-trigger__expand-icon--expand comet-icon--s\" focusable=\"false\" aria-hidden=\"true\">\n            <use xlink:href=\"";
output += runtime.suppressValue((lineno = 19, colno = 43, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "edde_static"), "edde_static", context, ["comet/2.x/comet.svg#caret-down"])), env.opts.autoescape);
output += "\"></use>\n        </svg>\n        <svg class=\"comet-flyout-trigger__expand-icon comet-flyout-trigger__expand-icon--close comet-icon--s\" focusable=\"false\" aria-hidden=\"true\">\n            <use xlink:href=\"";
output += runtime.suppressValue((lineno = 22, colno = 43, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "edde_static"), "edde_static", context, ["comet/2.x/comet.svg#caret-up"])), env.opts.autoescape);
output += "\"></use>\n        </svg>\n    </button>\n</div>\n";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};

})();
})();

define("common/components/edde/templates/nav/includes/product_bar/_flyout_trigger", function(){});

