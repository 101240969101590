define('common/components/learn-router/views/layout',[
    'app',
    'i18next',
    'common/components/comet/core-behaviors',
    'common/components/comet/behaviors/aria-tab',
    'common/components/comet/behaviors/flyout-menu',
    'dist/apps/error-page/main',
    'njs!common/templates/error_pages/_error',
    'njs!common/templates/error_pages/_no_access'

], function(
    app,
    i18next,
    CoreBehaviors,
    AriaTabBehavior,
    FlyOutBehavior,
    VueError,
    errorTemplate
) {
    'use strict';

    return Mn.LayoutView.extend({

        "el": (function(){
            if ($(".comet-page-shell").length > 0) {
                return ".comet-page-shell";
            } else if ($("#take-over-modal").length > 0) {
                return "#take-over-modal";
            } else {
                return "#scroll-content";
            }
        }()),

        "regions": {
            "header": "#header-wrap",
            "footer": ".footer-container",
            "productBar": ".comet-product-bar-container",
            "content": "#de-content-wrapper",
            "globalNavMenu": ".comet-page-shell__global-nav-bar-inner",
            "banner": ".search-opt-in-out-banner-container"
        },

        "events": {
            "click .do-window-open": "openLinkInNewWindow",
            "click .do-push-state": "doPushState",
            "click .footer-cobranding__close": "dismissCobrandingFooter",
            "tap .do-push-state": "doPushState",
            "submit .do-form-submit": "doFormSubmit"
        },

        "behaviors": _.extend({}, CoreBehaviors, {
            "AriaTabBehavior": {
                "behaviorClass": AriaTabBehavior
            },

            "FlyOutBehavior": {
                "behaviorClass": FlyOutBehavior
            }
        }),

        "initialize": function(opts){
            if (opts && opts.user){
                this.user = opts.user;
            }
            if (opts && opts.navigation){
                this.navigation = opts.navigation;
            }
            // add product bar, search autocomplete, etc...
            if (this.$('.comet-product-bar:not(.comet-product-bar--no-js-render)').length) {
                require([
                    'common/components/product-bar/views/product-bar',
                ], _.bind(function (ProductBar) {
                    this.getRegion('productBar').attachView(new ProductBar({
                        "model": this.model,
                        "user": this.user,
                        "navigation": this.navigation
                    }));
                    this.getRegion('productBar').currentView.onRender();
                }, this));
            } else if (this.$('#nebula-product-bar-container').length) {
                require([
                    'common/components/product-bar/views/product-bar-2022'
                ], _.bind(function (ProductBar2022) {
                    this.getRegion('productBar').attachView(new ProductBar2022({
                        "model": this.model,
                        "navigation": this.navigation
                    }));
                    this.getRegion('productBar').currentView.onRender();
                }, this));
            } else {
                require([
                    'common/components/product-bar/views/product-bar-light'
                ], _.bind(function (ProductBarLight) {
                    this.getRegion('productBar').attachView(new ProductBarLight());
                }, this));
            }

            if (this.$('#search-opt-in-out-banner-container').length) {
                require([
                    'common/components/learn-router/views/search-opt-in-out-banner'
                ], _.bind(function (SearchOptInOutBanner) {
                    this.getRegion('banner').attachView(new SearchOptInOutBanner({
                        'model': this.model
                    }));
                    this.getRegion('banner').currentView.render();
                }, this));
            }

            if (this.$('#nebula-footer-container').length) {
                require([
                    'common/components/learn-router/views/nebula-footer'
                ], _.bind(function (NebulaFooter) {
                    this.getRegion('footer').attachView(new NebulaFooter({
                        "model": this.model
                    }));
                    this.getRegion('footer').currentView.onRender();
                }, this));
            }

            this.bindUIElements();
            this.initializeRadioChannels();
            this.initDocClick();
            this.checkForScrollClass($(window).scrollTop());

            $(window).on('scroll', _.bind(_.debounce(this.onScroll, 150), this));

            this.$cobrandingFooter = this.$('.footer-cobranding');
            this.cobrandingFooterDismissed = sessionStorage.getItem('cobranding-footer-dismissed') === "true";

            if (this.$cobrandingFooter.length) {
                this.$actionButton = this.$('.de-floating-action-button__new-quicklist');
                this.showCobrandingFooter();
            }

            $('.skip-navigation-link').click(function(e){
                var $skipToMainOverride = $('[data-skip-to-main]');
                if ($skipToMainOverride.length) {
                    var $firstFocusable = $skipToMainOverride.find(':focusable');
                    if ($firstFocusable.first().length) {
                        e.preventDefault();
                        $firstFocusable.first().focus();
                    }
                }
            });

        },

        "initDocClick": function(){
            $(document).click(_.bind(function(e) {
                this.channels.learnRouter.trigger('document:click', $(e.target));
            }, this));
        },

        "initializeRadioChannels": function(){
            this.channels = {
                "learnRouter": Backbone.Radio.channel('learn-router')
            };
            this.listenTo(this.channels.learnRouter, {
                'router:navigate': this.doPushState
            });
            $(window).on('resize', _.bind(_.debounce(this.triggerWindowResize, 150), this));
        },

        "triggerWindowResize": function() {
            this.channels.learnRouter.trigger('window:resize', $(window).width());
            this.positionCobrandingFooter();
        },

        "onScroll": function() {
            var scrollTop = $(window).scrollTop();
            this.channels.learnRouter.trigger('window:scroll', scrollTop);
            this.checkForScrollClass(scrollTop);
            this.positionCobrandingFooter();
        },

        "checkForScrollClass": function(scrollTop) {
            var $body = $("body");

            if (scrollTop >= 10) {
                $body.addClass("body--scrolled");
            } else {
                $body.removeClass("body--scrolled");
            }
        },

        "showCobrandingFooter": function() {
            var footerInViewport = !!this.$(".footer-container:in-viewport").length;

            if (!footerInViewport) {
                if (!this.cobrandingFooterDismissed) {
                    this.$cobrandingFooter.addClass('footer-cobranding--fixed');
                    this.$actionButton.addClass('de-floating-action-button__cobranding-footer-active');
                }
            }
        },

        "positionCobrandingFooter": function() {
            if (this.$cobrandingFooter.length && !this.cobrandingFooterDismissed) {

                var footerInViewport = !!this.$(".footer-container:in-viewport").length;

                if (footerInViewport) {
                    this.$cobrandingFooter.removeClass('footer-cobranding--fixed');
                    this.$actionButton.removeClass('de-floating-action-button__cobranding-footer-active');
                } else {
                    this.$actionButton.addClass('de-floating-action-button__cobranding-footer-active');
                    this.$cobrandingFooter.addClass('footer-cobranding--fixed');
                }
            }
        },

        "dismissCobrandingFooter": function() {
            this.$cobrandingFooter.removeClass('footer-cobranding--fixed');
            this.$actionButton.removeClass('de-floating-action-button__cobranding-footer-active');
            sessionStorage.setItem('cobranding-footer-dismissed', true);
            this.cobrandingFooterDismissed = true;
        },

        "showErrorPage": function(xhr) {
            this.$("#de-content-wrapper").html(errorTemplate(xhr.responseJSON || {}));
            this.vueError = new VueError();
            this.vueError.start();
        },

        "clearError": function(){
            if (this.vueError) {
                this.vueError.destroy();
            }
        },

        "showClientSideTimeoutPage": function(){
            this.showTimeOutError({
                "headline": i18next.t("Launch has been delayed."),
                "message": i18next.t("The page has not responded and the request has timed out due to connection issues. Please wait for a few minutes and retry."),
                "error": i18next.t("Error 408=Request Timeout")
            });
        },

        "showServerSideTimeoutPage": function(){
            this.showTimeOutError({
                "headline": i18next.t("Traffic jam on the way to launch."),
                "message": i18next.t("The page has not responded and the request has timed out due to server issues. Please wait for a few minutes and retry."),
                "error": i18next.t("Error 504=Gateway Timeout")
            });
        },

        "showTimeOutError": function(msgObj) {
            this.$("#de-content-wrapper").html(errorTemplate(msgObj));
            this.vueError = new VueError();
            this.vueError.start();
        },

        "openLinkInNewWindow": function (e) {
            var $anchor = $(e.currentTarget),
                url = $anchor.attr("href"),
                newWindowFeatures = $anchor.data("window-features"),
                newWindowName = $anchor.data("new-window-name") || "",
                langDir = i18next.dir(i18next.language),
                $window = $(window),
                windowWidth = $window.width(),
                windowHeight = $window.height();

            e.preventDefault();

            if (newWindowFeatures === "match") {
                newWindowFeatures = 'width=' + windowWidth + ',height=' + windowHeight;
                newWindowFeatures += 'menubar=no,location=no,resizable=no,scrollbars=1,status=no';
                newWindowFeatures += ',screenX=' + (screen.width - windowWidth) / 2;
                newWindowFeatures += ',left=' + (screen.width - windowWidth) / 2;
            } else {

                var leftPosition = langDir === 'rtl' ? windowWidth : '0';
                newWindowFeatures = newWindowFeatures + ',left=' + leftPosition;
            }

            window.open(url, newWindowName, newWindowFeatures);
        },

        "navigate": function (url) {
            url = url.trim();

            var protocol = window.location.protocol,
                host = window.location.host;

            window.DEAnalytics && window.DEAnalytics.ajaxRefreshPageTrack({
                "url": protocol + "//" + host + url,
                "previousUrl": window.location.href
            });

            // set DeTracker url to current before updating
            window.DeTracker && DeTracker.extendPageData({
                "url": window.location.href
            });

            Backbone.history.navigate(
                url,
                {
                    "trigger": true
                }
            );
        },

        "doPushState": function (e, data) {
            // hack to make content preview always reload from server
            if (/^content./.test(window.location.hostname)){
                return;
            }
            var url = "",
                $currentTarget = e.currentTarget && $(e.currentTarget);
            if ($currentTarget){ //event
                // only push the state if the end user did not do command click or
                // control click and the end user will get the expected behavior of
                // the page opening in a new tab
                if (!e.ctrlKey && !e.metaKey) {
                    e.preventDefault();
                    url = $(e.currentTarget).attr("href") || $(e.currentTarget).attr("data-href");
                    if ($currentTarget.data("loadLight")) {
                        this.channels.learnRouter.replyOnce('router:next-load-light', true);
                    }
                    if ($currentTarget.data("skipLoad")) {
                        this.channels.learnRouter.replyOnce('router:next-skip-load', true);
                    }
                    if ($currentTarget.hasClass("scroll-top-on-click")) {
                        window.scrollTo(0, 0);
                    }
                } else {
                    return;
                }
            } else { // string
                if (data) {
                    this.channels.learnRouter.replyOnce('router:next-skip-load', data);
                }
                url = e;
            }

            this.navigate(url);
        },

        "doFormSubmit": function (event) {
            var $form = $(event.currentTarget),
                formData,
                method,
                action;

            event.preventDefault();

            if (!$form.find('.comet-form--error:visible').length) {

                formData = $form.serialize();

                method = $form.attr('method') || 'GET'; // default html form-action is GET
                method = method.toUpperCase().trim();

                action = $form.attr('action');
                if (action) {
                    action = $form.attr('action').trim();
                } else {
                    action = window.location.href;
                }

                if (method === 'GET') {
                    action = action.replace(/(\?[^#]+)?(#.*)?$/, '?' + formData);
                    this.navigate(action);
                    return;
                }

                if ($form.hasClass("scroll-top-on-submit")) {
                    window.scrollTo(0, 0);
                }

                this.trigger('do-form-post', {
                    "data": formData,
                    "method": method,
                    "url": action
                });
            }
        }
    });
});

