(function() {(window.nunjucksPrecompiled = window.nunjucksPrecompiled || {})["edde/nav/includes/quicklist/_quicklist_panel.html"] = (function() {
function root(env, context, frame, runtime, cb) {
var lineno = 0;
var colno = 0;
var output = "";
try {
var parentTemplate = null;
if(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "init_data")),"quicklist")) {
output += "\n    <div class=\"comet-page-shell__panel comet-page-shell__panel--quicklist\" aria-labelledby=\"comet-product-bar__quicklist-button\" role=\"region\" aria-hidden=\"true\">\n        ";
var tasks = [];
tasks.push(
function(callback) {
env.getTemplate("edde/nav/includes/quicklist/_quicklist_header.html", false, "edde/nav/includes/quicklist/_quicklist_panel.html", false, function(t_2,t_1) {
if(t_2) { cb(t_2); return; }
callback(null,t_1);});
});
tasks.push(
function(template, callback){
template.render(context.getVariables(), frame, function(t_4,t_3) {
if(t_4) { cb(t_4); return; }
callback(null,t_3);});
});
tasks.push(
function(result, callback){
output += result;
callback(null);
});
env.waterfall(tasks, function(){
output += "\n        <div class=\"comet-page-shell__panel-content comet-page-shell__panel--quicklist-content\">\n            <ul class=\"comet-page-shell__panel--quicklist__asset-list\">\n                <!-- QUICK LIST ITEMS DYNAMICALLY ADDED HERE -->\n            </ul>\n        </div>\n        <div class=\"comet-page-shell__panel-content-empty\">\n            <img class=\"comet-page-shell__panel-content-empty__image\" src=\"";
output += runtime.suppressValue((lineno = 9, colno = 89, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "edde_static"), "edde_static", context, ["common/components/learn-router/images/quicklist-empty-state.gif"])), env.opts.autoescape);
output += "\" alt=\"\">\n            <h3 class=\"comet-page-shell__panel-content-empty__header\">";
output += runtime.suppressValue((lineno = 10, colno = 74, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "_"), "_", context, ["Start Adding!"])), env.opts.autoescape);
output += "</h3>\n            <p class=\"comet-page-shell__panel-content-empty__paragraph\">";
output += runtime.suppressValue(env.getFilter("safe").call(context, (lineno = 11, colno = 76, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "_"), "_", context, ["Looks like you haven't added <br>items to your Favorites. You can add <br>items to easily find them later."]))), env.opts.autoescape);
output += "</p>\n        </div>\n        <div class=\"comet-page-shell__panel-content-controls\">\n            <button class=\"comet-button comet-button--ghost comet-button--on-dark js-comet-page-shell__panel--load-more\">";
output += runtime.suppressValue((lineno = 14, colno = 125, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "_"), "_", context, ["Load More"])), env.opts.autoescape);
output += "</button>\n            <button class=\"comet-button comet-button--flat comet-button--on-dark comet-button--icon-with-text js-comet-page-shell__panel--back-to-top\">\n                <span class=\"comet-button__inner\">\n                    <svg class=\"comet-button__icon comet-button__icon--left\" aria-hidden=\"true\" focusable=\"false\">\n                        <use xmlns:xlink=\"http://www.w3.org/1999/xlink\" xlink:href=\"";
output += runtime.suppressValue((lineno = 18, colno = 98, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "edde_static"), "edde_static", context, ["comet/2.x/comet.svg#arrow-up"])), env.opts.autoescape);
output += "\"></use>\n                    </svg>\n                    <span class=\"comet-button__text\">\n                        ";
output += runtime.suppressValue((lineno = 21, colno = 28, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "_"), "_", context, ["Back to Top"])), env.opts.autoescape);
output += "\n                    </span>\n                </span>\n            </button>\n        </div>\n    </div>\n";
});
}
output += "\n";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};

})();
})();

define("common/components/edde/templates/nav/includes/quicklist/_quicklist_panel", function(){});

