define('common/components/modals/views/builder',[
    'common/components/modals/views/modal',
    'common/components/modals/templates/builder'

], function(ModalView, template) {
    'use strict';

    return ModalView.extend({

        "footer": {
            "dismiss": "Cancel"
        },

        "header": {
            "title": "Add to Builder"
        },

        "template": template,

        "initialize": function(opts) {
            this.responseHTML = opts.responseHTML;
        },

        "serializeData": function() {
            return {
                "responseHTML": this.responseHTML
            };
        }

    });

});

