/*globals stButtons swfobject gapi stLight */
define('common/components/modals/views/share',[
    'i18next',
    'common/components/modals/views/modal',
    'njs!common/templates/share/includes/_share',
    'app'
], function(i18next, ModalView, template, app) {
    'use strict';

    return ModalView.extend({

        "header": {
            "title": "Share"
        },

        "template": template,

        "ui": {
            "copy": ".copy-link-text",
            "classrooms": "#gs-share-classroom",
            "pinit": ".pinterest-share-button"
        },

        "events": {
            "click @ui.pinit": "pinterestTakeOver",
            "click .share-button:not(.pinterest-share-button,.email-share-button)": "openShareLink",
            "click .comet-button--primary": "copyLink",
            "click .email-share-button": "trackEmailClick"
        },

        "initialize": function(shareData) {
            // HAX, AFAIK, the only way we can track the
            // google classroom share click, because iframe
            window.googleCRShareCB = function(){
                window.ga && ga('send', 'event', 'Link', 'Click', 'Share|GoogleClassrooms');
            };

            var data = shareData.model;
            this.title = data.title || data.headline;
            this.id = data.guid;
            this.description = data.description || '';
            this.path = data.path || (typeof data.get === "function" && data.get("link")) || "/learn/player/" + this.id;
        },

        "serializeData": function() {
            var title = this.title;

            // is it a full path?
            if (/^https?:\/\//i.test(this.path)) {
                this.link = this.path;
            } else {
                this.link = (app.initData.get('sso_url') || window.location.origin) + this.path;
            }

            return {
                "headline": title,
                "link": this.link,
                "description": this.description,
                "shareInfo": {
                    "twitterHandle": "@DiscoveryEd"
                },
                "referrer": window.location.href
            };
        },

        "getVendorAssets": function() {
            // google classroom
            $.getScript('//apis.google.com/js/platform.js', _.bind(function() {
                gapi.sharetoclassroom.render({
                    "container": "gs-share-classroom"
                });
                this.$(":focusable").not('.js-focus-fence').first().focus();
            }, this));

            return this;
        },

        "openShareLink": function(e){

            window.ga && ga('send', 'event', 'Link', 'Click', 'Share|' + $(e.currentTarget).text());

            var $anchor = $(e.currentTarget),
                url = $anchor.attr("href"),
                newWindowFeatures = $anchor.data("window-features");
            e.preventDefault();
            window.open(url, "share", newWindowFeatures);
        },

        "pinterestTakeOver": function(e){
            window.ga && ga('send', 'event', 'Link', 'Click', 'Share|Pinterest');
            e.preventDefault();
            $.getScript('//assets.pinterest.com/js/pinit_main.js', function() {
                PinUtils.pinAny();
            });
        },

        "trackEmailClick": function(){
            window.ga && ga('send', 'event', 'Link', 'Click', 'Share|Email');
        },

        "onRender": function(shareData) {
            if (document.queryCommandSupported("copy")) {
                this.$(".comet-button--primary").show();
            }
            this.getVendorAssets();
        },

        "copyLink": function(e){
            e.stopPropagation();
            window.ga && ga('send', 'event', 'Link', 'Click', 'Share|Copy');
            this.$("#copy-link").select();
            document.execCommand('copy');

            this.trigger('alert', {
                "message": i18next.t("The link has been copied to your clipboard"),
                "type": "success"
            });
        }

    });

});

