(function() {(window.nunjucksPrecompiled = window.nunjucksPrecompiled || {})["edde/nav/includes/product_bar/_user_context_dropdown.html"] = (function() {
function root(env, context, frame, runtime, cb) {
var lineno = 0;
var colno = 0;
var output = "";
try {
var parentTemplate = null;
output += "<div class=\"comet-product-bar__profile-dropdown comet-dropdown-menu comet-popover comet-popover--bottom-right-aligned\" id=\"comet-product-bar__dropdown-menu--profile\" data-comet-popover-align=\"left\" aria-hidden=\"true\" aria-labelledby=\"profile-dropdown-trigger\" role=\"region\">\n    <div class=\"comet-list-group comet-theme--dark-background comet-dropdown-menu__list-group comet-list-group--no-hairlines\">\n        <div class=\"comet-product-bar__profile-dropdown-header\">\n            <h3 class=\"comet-product-bar__profile-dropdown-header-title\">";
output += runtime.suppressValue((lineno = 3, colno = 77, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "_"), "_", context, ["Account"])), env.opts.autoescape);
output += "</h3>\n            <button class=\"comet-product-bar__profile-dropdown-header-close\" data-comet-popover=\"comet-product-bar__dropdown-menu--profile\">\n                <svg class=\"comet-product-bar__profile-dropdown-header-close-icon\" focusable=\"false\" aria-hidden=\"true\">\n                    <use xlink:href=\"";
output += runtime.suppressValue((lineno = 6, colno = 51, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "edde_static"), "edde_static", context, ["comet/2.x/comet.svg#x"])), env.opts.autoescape);
output += "\"></use>\n                </svg>\n            </button>\n        </div>\n        <ul class=\"comet-list-group__list\">\n            <li class=\"comet-list-group__row comet-product-bar__profile-dropdown-name-row\">\n                ";
if(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "init_data")),"user")),"is_student") || (runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "init_data")),"product_settings") && runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "init_data")),"product_settings")),"HIDEPROFILE"))) {
output += "\n                    <span ";
if(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "init_data")),"user")),"image")) {
output += "data-src=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "init_data")),"user")),"image"), env.opts.autoescape);
output += "\"";
;
}
output += " class=\"comet-list-group__avatar\"></span>\n                    <span class=\"comet-list-group__row-label\">\n                        <span class=\"comet-product-bar__view-profile-name-text\">\n                            ";
output += runtime.suppressValue((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "init_data")),"user")),"first_name")?runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "init_data")),"user")),"first_name"):""), env.opts.autoescape);
output += " ";
output += runtime.suppressValue((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "init_data")),"user")),"last_name")?runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "init_data")),"user")),"last_name"):""), env.opts.autoescape);
output += "\n                        </span>\n                    </span>\n                ";
;
}
else {
output += "\n                    <a class=\"comet-list-group__row-anchor\" href=\"";
output += runtime.suppressValue((lineno = 20, colno = 79, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "get_DE_url"), "get_DE_url", context, ["app"])), env.opts.autoescape);
output += "/learn/profile\">\n                        <span ";
if(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "init_data")),"user")),"image")) {
output += "data-src=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "init_data")),"user")),"image"), env.opts.autoescape);
output += "\"";
;
}
output += " class=\"comet-list-group__avatar\"></span>\n                        <span class=\"comet-list-group__row-label\">\n                            <span class=\"comet-product-bar__view-profile-name-text\">\n                                ";
output += runtime.suppressValue((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "init_data")),"user")),"first_name")?runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "init_data")),"user")),"first_name"):""), env.opts.autoescape);
output += " ";
output += runtime.suppressValue((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "init_data")),"user")),"last_name")?runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "init_data")),"user")),"last_name"):""), env.opts.autoescape);
output += "\n                            </span>\n                            <span class=\"comet-product-bar__view-profile-link-text\">";
output += runtime.suppressValue((lineno = 26, colno = 88, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "_"), "_", context, ["View Profile"])), env.opts.autoescape);
output += "</span>\n                        </span>\n                    </a>\n                ";
;
}
output += "\n            </li>\n            ";
if(runtime.contextOrFrameLookup(context, frame, "page_data") && runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "page_data")),"navigation") && runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "page_data")),"navigation")),"user") && runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "page_data")),"navigation")),"user")),"multisites")) {
output += "\n                ";
var tasks = [];
tasks.push(
function(callback) {
env.getTemplate("edde/nav/includes/product_bar/_multisite.html", false, "edde/nav/includes/product_bar/_user_context_dropdown.html", false, function(t_2,t_1) {
if(t_2) { cb(t_2); return; }
callback(null,t_1);});
});
tasks.push(
function(template, callback){
template.render(context.getVariables(), frame, function(t_4,t_3) {
if(t_4) { cb(t_4); return; }
callback(null,t_3);});
});
tasks.push(
function(result, callback){
output += result;
callback(null);
});
env.waterfall(tasks, function(){
output += "\n            ";
});
}
output += "\n            ";
if(runtime.contextOrFrameLookup(context, frame, "page_data") && runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "page_data")),"navigation") && runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "page_data")),"navigation")),"user") && runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "page_data")),"navigation")),"user")),"menulinks")) {
output += "\n                ";
frame = frame.push();
var t_7 = runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "page_data")),"navigation")),"user")),"menulinks");
if(t_7) {t_7 = runtime.fromIterator(t_7);
var t_6 = t_7.length;
for(var t_5=0; t_5 < t_7.length; t_5++) {
var t_8 = t_7[t_5];
frame.set("item", t_8);
frame.set("loop.index", t_5 + 1);
frame.set("loop.index0", t_5);
frame.set("loop.revindex", t_6 - t_5);
frame.set("loop.revindex0", t_6 - t_5 - 1);
frame.set("loop.first", t_5 === 0);
frame.set("loop.last", t_5 === t_6 - 1);
frame.set("loop.length", t_6);
output += "\n                    <li class=\"comet-list-group__row ";
if(runtime.inOperator("learn/logout",runtime.memberLookup((t_8),"url"))) {
output += " comet-product-bar__profile-dropdown-sign-out-row ";
;
}
output += "\">\n                        <a class=\"comet-list-group__row-anchor\" href=\"";
output += runtime.suppressValue(runtime.memberLookup((t_8),"url"), env.opts.autoescape);
output += "\">\n                            <span class=\"comet-list-group__row-label\">\n                                ";
output += runtime.suppressValue(runtime.memberLookup((t_8),"name"), env.opts.autoescape);
output += "\n                            </span>\n                        </a>\n                    </li>\n                ";
;
}
}
frame = frame.pop();
output += "\n            ";
;
}
output += "\n            ";
if(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "init_data")),"user")),"is_impersonation")) {
output += "\n                <li class=\"comet-list-group__row\">\n                    <a id=\"unimpersonate-link\" class=\"comet-list-group__row-anchor\" href=\"";
output += runtime.suppressValue((lineno = 47, colno = 103, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "get_DE_url"), "get_DE_url", context, ["admin"])), env.opts.autoescape);
output += "/users/unimpersonate\">\n                        <span class=\"comet-list-group__row-label comet-list-group__row-label--quit-impersonation\">\n                            <svg class=\"comet-list-group__row-label--quit-impersonation-svg\" focusable=\"false\" aria-hidden=\"true\">\n                                <use xlink:href=\"";
output += runtime.suppressValue((lineno = 50, colno = 63, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "edde_static"), "edde_static", context, ["comet/2.x/comet.svg#edit-profile"])), env.opts.autoescape);
output += "\"></use>\n                            </svg>";
output += runtime.suppressValue((lineno = 51, colno = 38, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "_"), "_", context, ["Quit Impersonation"])), env.opts.autoescape);
output += "\n                        </span>\n                    </a>\n                </li>\n            ";
;
}
output += "\n        </ul>\n    </div>\n</div>\n";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};

})();
})();

define("common/components/edde/templates/nav/includes/product_bar/_user_context_dropdown", function(){});

