(function() {(window.nunjucksPrecompiled = window.nunjucksPrecompiled || {})["edde/nav/includes/quicklist/_quicklist_button.html"] = (function() {
function root(env, context, frame, runtime, cb) {
var lineno = 0;
var colno = 0;
var output = "";
try {
var parentTemplate = null;
output += "<li class=\"comet-product-bar__action comet-product-bar__action--quicklist\">\n    <button class=\"comet-button comet-button--small comet-product-bar__action-link comet-product-bar__quicklist-button js-comet-product-bar__toggle-panel\" id=\"comet-product-bar__quicklist-button\">\n        ";
if(runtime.contextOrFrameLookup(context, frame, "count") || runtime.contextOrFrameLookup(context, frame, "count") == 0) {
output += "\n        <span aria-hidden=\"true\">";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "count"), env.opts.autoescape);
output += "</span>\n        <span class=\"comet-screenreader-only comet-product-bar__action--quicklist__screenreader-msg\">";
output += runtime.suppressValue((lineno = 4, colno = 105, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "_"), "_", context, ["Open Favorites"])), env.opts.autoescape);
output += "</span>\n        <span class=\"comet-screenreader-only\">";
output += runtime.suppressValue((lineno = 5, colno = 50, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "_"), "_", context, ["Contains %(total)s items",runtime.makeKeywordArgs({"total": runtime.contextOrFrameLookup(context, frame, "count")})])), env.opts.autoescape);
output += "</span>\n        ";
;
}
output += "\n        <div class=\"comet-product-bar__action--quicklist__icon\">\n            <span class=\"comet-product-bar__action--quicklist__icon--bar comet-product-bar__action--quicklist__icon--bar-0\"></span>\n            <span class=\"comet-product-bar__action--quicklist__icon--bar comet-product-bar__action--quicklist__icon--bar-1\"></span>\n            <span class=\"comet-product-bar__action--quicklist__icon--bar comet-product-bar__action--quicklist__icon--bar-2\"></span>\n            <span class=\"comet-product-bar__action--quicklist__icon--bar comet-product-bar__action--quicklist__icon--bar-3\"></span>\n        </div>\n        <div class=\"comet-product-bar__action__popover comet-popover comet-popover--bottom visible\" aria-hidden=\"true\">\n            <div class=\"comet-popover__nub\"></div>\n            ";
output += runtime.suppressValue((lineno = 15, colno = 16, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "_"), "_", context, ["Favorites"])), env.opts.autoescape);
output += "\n        </div>\n    </button>\n</li>";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};

})();
})();

define("common/components/edde/templates/nav/includes/quicklist/_quicklist_button", function(){});

