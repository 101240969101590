define('common/components/comet/behaviors/comet-toggletip',[], function () {
    "use strict";

    return Mn.Behavior.extend({
        "events": {
            "click @ui.toggleToggletip": "onClickToggleToggletip",
            "touchend @ui.toggleToggletip": "onClickToggleToggletip",

            "click @ui.closeToggletip": "onClickCloseToggletip",
            "touchend @ui.closeToggletip": "onClickCloseToggletip"
        },

        "ui": {

            "toggleToggletip": ".js-comet-toggletip-trigger",
            "closeToggletip": ".js-comet-toggletip-trigger-close"
        },

        "initialize": function () {
            this.onClickBody = _.bind(this.onClickBody, this);
            jQuery('body').on('click.comet-tt', this.onClickBody);
        },

        "onClickBody": function (event) {
            // DO NOT try to close the drop-down
            // if we just toggled it.
            if (this._toggleToggletip) {
                this._toggleToggletip = false;
                return;
            }

            this.closeToggletip(
                this.$('.js-comet-toggletip-trigger')
            );
        },

        "onClickToggleToggletip": function (event) {
            if (this._toggleToggletip) {
                return;
            }

            var $target = $(event.currentTarget);
            this._toggleToggletip = true;
            this.toggleToggletip($target);
        },

        "onClickCloseToggletip": function (event) {
            var $target = $(event.currentTarget),
                $btnToggletip;

            $btnToggletip = $target.closest('.comet-toggletip-content')
                                  .parent()
                                  .find('.js-comet-toggletip-trigger');

            this.closeToggletip($btnToggletip);
        },

        "toggleToggletip": function ($target) {
            var isOpen = !!$target.data("open");

            if (isOpen) {
                this.closeToggletip($target);
                return;
            }

            this.openToggletip($target);
        },

        "openToggletip": function ($target) {
            var $menu;

            $menu = $target.parent().find('.comet-toggletip-content');

            $target.trigger('comet-tt:before:open');

            $target.data("open", true)
                   .attr("aria-expanded", true)
                   .addClass(
                        [
                            "comet-toggletip-trigger--content-visible",
                            "comet-button--active",
                            "comet-button--hover"
                        ].join(' ')
                    );

            $menu.attr("aria-hidden", false)
                 .addClass("visible");

            $target.trigger('comet-tt:open');
        },

        "closeToggletip": function ($target) {
            var $menu;

            $menu = $target.parent().find('.comet-toggletip-content');

            $target.trigger('comet-tt:before:close');

            $target.data("open", false)
                   .attr("aria-expanded", false)
                   .removeClass(
                        [
                            "comet-toggletip-trigger--content-visible",
                            "comet-button--active",
                            "comet-button--hover"
                        ].join(' ')
                    );

            $menu.attr("aria-hidden", true)
                 .removeClass("visible");

            $target.trigger('comet-tt:close');
        },

        "onBeforeDestroy": function () {
            jQuery('body').off('click.comet-tt');
        }
    });
});

